import { User } from "fhir/fhir-extended"
import { ObjectSchema } from "yup"
import { AnyObject } from "yup/lib/types"
import { AssertsShape, TypeOfShape } from "yup/lib/object"

export type FilterProps = {
  status: string | undefined
  after: string | undefined
  before: string | undefined
}

export type UserActionsProps = {
  showDialog?: boolean
  user?: User
  dialogTitle?: DialogTitle
  content?: JSX.Element
  initialValue?: object
  validateSchema?: ObjectSchema<AnyObject, AnyObject, TypeOfShape<AnyObject>, AssertsShape<AnyObject>>
}
export enum DialogTitle {
  editEmail = "Edit email",
  inviteUser = "Invite user",
  changePassword = "Change password",
}

export enum Auth2faMethods {
  NONE,
  PHONE,
  SOFTWARE,
}

export type PasswordData = {
  id?: string
  temporalPassword?: string
}
