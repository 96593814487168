import { Field, FieldProps, ErrorMessage } from "formik"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { Checkbox } from "primereact/checkbox"

const CheckBoxField: FC<Props> = ({ field, label, className, onCheck }) => (
  <Field name={field}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div className={classNames("field flex items-center gap-1", className)}>
        <Checkbox
          inputId={name}
          name={name}
          onChange={(e) => {
            onChange(e)
            onCheck?.(!!e.checked)
          }}
          checked={value}
          className={classNames({ "p-invalid": touched && error })}
        />
        {label && (
          <label htmlFor={name} className="text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        <div className="flex items-start p-error h-2 mt-1">
          <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  label?: string
  className?: string
  rows?: number
  onCheck?(checked: boolean): void
}

export { CheckBoxField }
