import type { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core"
import { classNames } from "primereact/utils"

import { Button } from "../components/Buttons"

const FooterActions = ({ actions, className, actionsDisabled = false }: Props) => {
  return (
    <div
      className={classNames("flex flex-shrink-0 justify-end gap-3 px-4 py-4  border-gray-100 border-t-2", className)}
    >
      {actions.map(({ label, command, disabled, style = "primary", title, loading = false }, key) => (
        <Button
          key={key}
          buttonStyle={style as "default" | "primary" | "floating" | "outlined" | "text"}
          label={label}
          size="lg"
          disabled={disabled || actionsDisabled}
          title={title}
          onClick={command}
          loading={loading}
        />
      ))}
    </div>
  )
}

type Props = {
  actions: Action[]
  actionsDisabled?: boolean
  className?: string
}

export type Action = {
  label?: string
  command: () => void
  disabled?: boolean
  style?: string
  icon?: IconDefinition | IconName
  title?: string
  loading?: boolean
}

export { FooterActions }
