import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons"
import { humanNameAsString } from "fhir"
import { FormikHelpers, FormikValues } from "formik"
import { Divider } from "primereact/divider"
import { useId, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import {
  Button,
  ConfirmDialog,
  DialogFormContainer,
  EmailCheckContext,
  EmailCheckProvider,
  EmptyMessage,
  GroupedList,
  InputField,
  LoadingView,
  MasterDetailView,
  PractitionerApi,
  ReferenceDropdownField,
  SkeletonLoader,
  StackedListItem,
  useFiltersContext,
  useScreenContext,
  useSmartyAddressVerification,
  ViewHeader,
} from "commons"
import { DataContainerForm } from "commons/form/DataContainerForm"
import { IDENTIFIER_CODE } from "data"
import { useDiagnosticLaboratories } from "organizations"

import {
  useActiveDeactivePractitioner,
  useCreatePractitioner,
  useInvitePractitioner,
  usePractitioner,
  usePractitionerLabSetup,
  usePractitioners,
  useUpdatePractitioner,
} from "../hooks"
import { FilterProps } from "../types"
import { PractitionerFilters } from "./PractitionerFilters"
import { PractitionerForm } from "./PractitionerForm"
import { PractitionerInvite } from "./PractitionerInvite"
import { practModelBuilder } from "./practModelBuilder"
import { APIPractitionerValidationSchema, initialValues, LabSetupValidationSchema, sanitize } from "./validations"
import { PractitionerRoleModal } from "./PractitionerRoleModal"

const PractitionerList = () => {
  const {
    searchText,
    filters: { gender, email, organization, role, status },
    hasActiveFilters,
    onSearch,
    onFilter,
    onClearFilters,
  } = useFiltersContext<FilterProps>()
  const [showInviteDialog, setShowInviteDialog] = useState(false)
  const [showActiveDeactiveDialog, setShowActiveDeactiveDialog] = useState(false)
  const [showLabSetupDialog, setShowLabSetupDialog] = useState(false)
  const [pract, setPract] = useState<PractitionerApi | undefined>(undefined)
  const [showPRs, setShowPRs] = useState(false)
  const [showForm, setShowForm] = useState(false)

  const { practitioner, isLoading: isLoadingPractitioner } = usePractitioner(pract?.practitioner.id)
  const { updatePractitionerStatus, isUpdating: isChangingStatus } = useActiveDeactivePractitioner()
  const practitionerLabsAlreadyConfigure = useMemo(
    () => pract?.practitioner?.identifier?.filter((i) => i.type?.coding?.[0].code === IDENTIFIER_CODE.AN),
    [pract],
  )
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const { practitionerLabSetup, isPending } = usePractitionerLabSetup(pract?.practitioner.id ?? "", () =>
    setShowLabSetupDialog(false),
  )
  const { diagnosticLabs } = useDiagnosticLaboratories("")

  const { practitionerGroups, isLoading, count, total, hasNextPage, fetchNextPage } = usePractitioners(
    searchText,
    email,
    gender,
    organization,
    role,
    status,
  )

  const { invitePractitioner, isInviting } = useInvitePractitioner(
    pract?.practitioner?.id as string,
    humanNameAsString(pract?.practitioner?.name?.[0]),
    () => {
      setShowInviteDialog(false)
    },
  )
  const closeForm = () => {
    setPract(undefined)
    setShowForm(false)
  }

  const { createPractitioner, isAdding } = useCreatePractitioner(closeForm)
  const { updatePractitioner, isUpdating } = useUpdatePractitioner(closeForm)

  const edit = (practitioner: PractitionerApi) => {
    setPract(practitioner)
    setShowForm(true)
  }

  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const practitionerAction = (pract: PractitionerApi, isNew: boolean) =>
    isNew ? createPractitioner(pract) : updatePractitioner(pract)

  const onSubmit = async (practitioner: PractitionerApi, formikHelpers?: FormikHelpers<FormikValues> | undefined) => {
    const pract = sanitize(practitioner)
    const isNew = !practitioner.practitioner?.id

    if (pract?.practitioner?.address?.[0]) {
      await checkAddress(pract?.practitioner?.address?.[0], formikHelpers, () => {
        clearVerificationInfo()
        practitionerAction(pract, isNew)
      })
    } else {
      practitionerAction(pract, isNew)
    }
  }

  const handleActiveDeactivePractitioner = (pract: PractitionerApi) => {
    setPract(pract)
    setShowActiveDeactiveDialog(true)
  }
  const handlePractitionerInvite = (pract: PractitionerApi) => {
    setPract(pract)
    setShowInviteDialog(true)
  }
  const handlePractitionerLabSetup = (pract: PractitionerApi) => {
    setPract(pract)
    setShowLabSetupDialog(true)
  }
  const hideInviteDialog = () => {
    setShowInviteDialog(false)
    setPract(undefined)
  }
  const hideActiveDeactiveDialog = () => {
    setShowActiveDeactiveDialog(false)
    setPract(undefined)
  }
  const changeStatus = () => {
    updatePractitionerStatus(practitioner)
    setPract(undefined)
  }
  const hidePractitionerLabSetup = () => {
    setPract(undefined)
    setShowLabSetupDialog(false)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  const viewRoles = (pract: PractitionerApi) => {
    setPract(pract)
    setShowPRs(true)
  }

  return (
    <>
      <ViewHeader
        isSmallViewport={isSmallScreen}
        viewTitle="Practitioners"
        infoText={`Showing ${count} practitioners of ${total} found`}
        showMenu={() => setSidebarCollapsed(false)}
      />
      <MasterDetailView
        onSearch={onSearch}
        containerClassName="h-full overflow-hidden w-full"
        isSmallViewport={isSmallScreen}
        loading={isLoading}
        placeholder="Search practitioners"
        searchText={searchText}
        hasActiveFilters={hasActiveFilters}
        headerText={!isSmallScreen ? `Showing ${count} practitioners of ${total} found` : undefined}
        headerAction={{ icon: faPlus, label: "Add practitioner", command: () => setShowForm(true) }}
        filters={
          !showForm && (
            <PractitionerFilters
              initialValues={{ gender, email, organization, role, status }}
              onSearch={onFilter}
              onClearFilters={onClearFilters}
              hasActiveFilters={hasActiveFilters}
              isSmallViewport={isSmallScreen}
            />
          )
        }
        hiddenHeader={showForm}
      >
        {isLoading ? (
          loader()
        ) : (
          <EmailCheckProvider>
            <DataContainerForm
              hasData={count > 0}
              showForm={showForm}
              showAddButton={false}
              formTitle="Practitioner"
              iconDataNotFound={faUserPlus}
              //enableReinitialize
              formInitialValue={initialValues(practitioner)}
              validationSchema={APIPractitionerValidationSchema("practitioner")}
              onSubmit={onSubmit}
              onCancel={closeForm}
              form={isLoadingPractitioner ? <LoadingView /> : <PractitionerForm />}
              onButtonAddClick={() => setShowForm(true)}
              cancelButtonLabel="Close"
              customSaveButton={() => (
                <EmailCheckContext.Consumer>
                  {({ isCheckingEmailInUse }) => (
                    <Button
                      label="Save"
                      disabled={isCheckingEmailInUse}
                      loading={isAdding || isUpdating}
                      type="submit"
                    />
                  )}
                </EmailCheckContext.Consumer>
              )}
              customAddButtonText="Add practitioner"
              subMessageDataNotFound="Get started by adding a practitioner"
            >
              {!showForm && (
                <div className="flex flex-col overflow-auto grow pt-4">
                  <InfiniteScroll
                    hasMore={hasNextPage}
                    loadMore={() => fetchNextPage()}
                    useWindow={false}
                    loader={loader()}
                  >
                    <GroupedList
                      className="grow"
                      groups={practitionerGroups}
                      renderDivider={(name) => (
                        <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                          <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                        </div>
                      )}
                      renderItem={(pract) => (
                        <>
                          <StackedListItem
                            itemPadding
                            modelData={practModelBuilder(
                              pract ?? {},
                              edit,
                              handleActiveDeactivePractitioner,
                              handlePractitionerInvite,
                              handlePractitionerLabSetup,
                              viewRoles,
                            )}
                          />
                        </>
                      )}
                      renderEmptyState={
                        <EmptyMessage
                          message="No practitioners found"
                          icon={faUserPlus}
                          subMessage="Get started by adding a practitioner"
                          itemTitle="practitioner"
                        />
                      }
                    />
                  </InfiniteScroll>

                  <ConfirmDialog
                    visible={showActiveDeactiveDialog || isChangingStatus}
                    onConfirm={changeStatus}
                    hideDialog={hideActiveDeactiveDialog}
                    isLoading={isChangingStatus}
                    confirmText={`Do you want to ${
                      pract?.practitioner?.active === false ? "activate" : "deactivate"
                    } this practitioner?`}
                  />

                  {showInviteDialog && (
                    <PractitionerInvite
                      showInviteDialog={showInviteDialog}
                      practitioner={pract?.practitioner ?? {}}
                      isInviting={isInviting}
                      hideInviteDialog={hideInviteDialog}
                      invitePractitioner={invitePractitioner}
                    />
                  )}
                  {showLabSetupDialog && (
                    <DialogFormContainer
                      title="Laboratory setup"
                      showForm={showLabSetupDialog || isPending}
                      onCancel={hidePractitionerLabSetup}
                      onSubmit={(data) => practitionerLabSetup(data ?? {})}
                      initialValue={{ value: "", organization: undefined }}
                      validationSchema={LabSetupValidationSchema}
                    >
                      <div className="w-full pb-3">
                        {!!practitionerLabsAlreadyConfigure?.length && (
                          <section className="flex flex-col mb-3">
                            <h3 className="text-gray-700 font-medium mb-1">Fully configured labs:</h3>
                            <ul>
                              {practitionerLabsAlreadyConfigure.map((i, index) => (
                                <li className="text-sm text-gray-400" key={i.id ?? index}>
                                  <span title="Assigner">{i.assigner?.display ?? "Unspecified laboratory"}</span> -{" "}
                                  <span title="Account number">{i.value ?? "Unspecified account number"}</span>
                                </li>
                              ))}
                            </ul>
                            <Divider />
                          </section>
                        )}
                        <InputField field="value" label="Account number" />
                        <ReferenceDropdownField field="organization" label="Organization" options={diagnosticLabs} />
                      </div>
                    </DialogFormContainer>
                  )}

                  {showPRs && <PractitionerRoleModal visible={showPRs} pract={pract} setVisible={setShowPRs} />}
                </div>
              )}
            </DataContainerForm>
          </EmailCheckProvider>
        )}
      </MasterDetailView>
    </>
  )
}

export { PractitionerList }
