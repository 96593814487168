import { FC } from "react"
import { isValid, format, parseISO } from "date-fns"
import { useSearchParams } from "react-router-dom"
import { formatInTimeZone } from "date-fns-tz"

import { FiltersContextProvider } from "commons"
import { formatsByTypes } from "data"

import { FilterProps } from "../types"
import { UserList } from "./UsersList"

const UsersView: FC = () => {
  const [searchParams] = useSearchParams()
  const initialFilters = parseParamsToFilters(searchParams)

  return (
    <FiltersContextProvider
      initialFilters={initialFilters}
      setUrlFilters
      filtersKeysValueMap={{
        after: (date) =>
          date && isValid(parseISO(new Date(date as string).toISOString()))
            ? format(date as string, formatsByTypes.ISO_8601_DATE)
            : undefined,
        before: (date) =>
          date && isValid(parseISO(new Date(date as string).toISOString()))
            ? format(date as string, formatsByTypes.ISO_8601_DATE)
            : undefined,
        status: (val) => (val === "true" ? "active" : val === "false" ? "deactivated" : undefined),
      }}
    >
      <UserList />
    </FiltersContextProvider>
  )
}

const DFAULT_FILTERS: FilterProps = {
  status: undefined,
  after: undefined,
  before: undefined,
}

export { UsersView }

const parseParamsToFilters = (searchParams: URLSearchParams): FilterProps => ({
  status:
    searchParams.get("status") === "active"
      ? "true"
      : searchParams.get("status") === "deactivated"
        ? "false"
        : DFAULT_FILTERS.status,
  after: searchParams.get("after")
    ? formatInTimeZone(searchParams.get("after") as string, "UTC", formatsByTypes.ISO_8601_DATE)
    : DFAULT_FILTERS.after,
  before: searchParams.get("before")
    ? formatInTimeZone(searchParams.get("before") as string, "UTC", formatsByTypes.ISO_8601_DATE)
    : DFAULT_FILTERS.before,
})
