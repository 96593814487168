import { classNames } from "primereact/utils"
import { CSSProperties, useState, type FC, type PropsWithChildren, type ReactNode } from "react"
import { faFilter, faTimes } from "@fortawesome/pro-solid-svg-icons"

import { type Action, Button, SearchInput } from "commons"
import { Sidebar } from "primereact/sidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MasterDetailView: FC<PropsWithChildren<Props>> = ({
  children,
  filters,
  searchText,
  loading,
  placeholder,
  onSearch,
  headerAction,
  headerText,
  overlayStyles,
  headerExternalAction,
  isSmallViewport = false,
  hasActiveFilters = false,
  containerClassName = "overflow-auto grow min-h-max",
  hiddenHeader,
}) => {
  const [showOverlay, setShowOverlay] = useState(false)

  return (
    <>
      {!hiddenHeader && (
        <header className="flex flex-wrap gap-y-2 lg:flex-nowrap items-center w-full lg:justify-between pb-2 lg:py-4 px-6 border-b drop-shadow">
          <div className="flex lg:flex-wrap lg:max-w-80 xl:max-w-72 w-full">
            {!!headerExternalAction && isSmallViewport && (
              <Button
                {...headerExternalAction}
                className="mr-2 items-center h-[2.1rem] w-10"
                onClick={headerExternalAction.command}
                size="lg"
                buttonStyle="default"
              />
            )}
            <SearchInput
              initialValue={searchText}
              search={onSearch}
              autoFocus={false}
              placeholder={placeholder}
              isLoading={loading}
              className="w-full"
            />
            {!!filters && isSmallViewport && (
              <>
                <Button
                  icon={faFilter}
                  iconClassName={!hasActiveFilters ? "text-slate-400" : "text-primary"}
                  buttonStyle="default"
                  size="lg"
                  className="ml-2 items-center h-[2.1rem] w-10"
                  onClick={() => setShowOverlay(true)}
                  loading={loading}
                />
                <Sidebar
                  visible={showOverlay}
                  onHide={() => setShowOverlay(false)}
                  dismissable
                  showCloseIcon={false}
                  id="overlay_filters_panel"
                  style={overlayStyles}
                  position="bottom"
                  className="h-auto rounded-t-xl"
                  closeOnEscape
                  pt={{ header: { className: "px-3 py-5" } }}
                  header={
                    <header className="w-full flex justify-between">
                      <h2 className="font-medium">Filter</h2>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="font-medium text-lg cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      />
                    </header>
                  }
                >
                  <div className="flex flex-col h-full bg-white gap-4 p-3">{filters}</div>
                </Sidebar>
              </>
            )}

            {!!headerText && <p className="truncate text-xs text-gray-400 w-full mt-1">{headerText}</p>}
          </div>

          <div className="inline-flex gap-x-3 overflow-hidden items-center justify-end w-full p-1">
            {!!headerAction && (
              <Button
                {...headerAction}
                onClick={headerAction.command}
                size="sm"
                buttonStyle="primary"
                className="whitespace-nowrap"
              />
            )}
          </div>
        </header>
      )}
      <section className="grid grid-cols-5 gap-4 grow lg:p-4 bg-gray-100 h-full overflow-hidden @container">
        {!!filters && !isSmallViewport && (
          <div className="flex flex-col h-max bg-white gap-4 col-span-full @6xl:col-span-2 @7xl:col-span-1 @6xl:rounded-2xl p-4">
            {filters}
          </div>
        )}
        <div
          className={classNames(
            "col-span-full  @6xl:rounded-2xl px-4 bg-white",
            containerClassName,
            { "@6xl:col-span-3 @7xl:col-span-4": !!filters },
            { "col-span-5": !filters },
          )}
        >
          {children}
        </div>
      </section>
    </>
  )
}

type Props = {
  filters?: ReactNode
  searchText?: string
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  placeholder?: string
  loading?: boolean
  onSearch: (searchText: string) => void
  containerClassName?: string
  headerText?: string
  headerAction?: Action
  overlayStyles?: CSSProperties
  headerExternalAction?: Action
  hiddenHeader?: boolean
}

export { MasterDetailView }
