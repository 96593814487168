import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Reference } from "fhir"
import { ErrorMessage, Field, FieldProps } from "formik"
import { AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { FC, ReactNode, startTransition, useEffect, useRef, useState } from "react"

const AutoCompleteReferenceField: FC<Props> = ({
  field,
  label,
  className,
  setFilter,
  optionList,
  isLoading,
  readonly,
  disabled,
  multiple,
  validation,
  horizontal,
  inputClassName = "",
  customItemTemplate,
  showClearIcon,
}) => {
  const [suggestionList, setSuggestionList] = useState<Reference[]>([])
  const isLoadingRef = useRef(isLoading)

  const itemTemplate = (item: Reference) => {
    return <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{item.display}</span>
  }

  useEffect(() => {
    if (!isLoading && isLoadingRef.current) {
      isLoadingRef.current = false
      setSuggestionList(optionList ?? [])
    }

    isLoadingRef.current = isLoading
  }, [optionList, isLoading])

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
        return (
          <div
            className={classNames(
              "field space-y-2 relative",
              horizontal ? "inline-flex justify-between items-center" : "flex flex-col",
              className,
            )}
          >
            {label && (
              <label
                htmlFor={name}
                className={classNames("text-sm font-medium text-gray-700 mt-3", { "mr-8 mb-0 mt-2": horizontal })}
              >
                {label}
              </label>
            )}
            <div className="flex flex-col w-full">
              {value?.id && !isLoading && showClearIcon && <ClearInputAction onClick={() => setFieldValue(name, "")} />}
              <AutoComplete
                id={name}
                name={name}
                field="display"
                disabled={disabled}
                readOnly={readonly}
                suggestions={suggestionList}
                delay={400}
                minLength={3}
                multiple={multiple}
                selectedItemTemplate={multiple && itemTemplate}
                completeMethod={(e) => {
                  if (e.query.length >= 3) {
                    startTransition(() => setFilter(e.query))
                  }
                }}
                onBlur={() => {
                  if (!multiple && !(value as Reference)?.id) {
                    setFilter("")
                    setFieldValue(name, "")
                  }
                }}
                onChange={(e) => {
                  multiple ? onChange(e) : setFieldValue(name, e.value ? { display: e.value } : "")
                }}
                onSelect={(e) => {
                  const newRef = {
                    id: e.value.id,
                    display: e.value.display,
                    resourceType: e.value.resourceType,
                  } as Reference
                  multiple ? setFieldValue(name, [...(value ?? []), newRef]) : setFieldValue(name, newRef)
                }}
                value={multiple ? value : value?.display}
                inputClassName={classNames("w-full", inputClassName)}
                className={classNames("p-inputtext-sm", { "p-invalid": touched && error, horizontal })}
                itemTemplate={customItemTemplate ?? itemTemplate}
              />

              <div className="flex items-start p-error h-2 mt-1">
                <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
              </div>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

const ClearInputAction: React.FC<{ className?: string; onClick: () => void }> = ({
  className = "right-2 text-gray-500",
  onClick,
}: {
  className?: string
  onClick: () => void
}) => (
  <div title="Clear filter" className={`absolute cursor-pointer top-12 flex items-end z-10 ${className}`}>
    <FontAwesomeIcon icon={faTimes} size="lg" onClick={onClick} />
  </div>
)

type Props = {
  field: string
  label?: string
  className?: string
  readonly?: boolean
  disabled?: boolean
  multiple?: boolean
  horizontal?: boolean
  setFilter(query: string): void
  optionList?: Reference[]
  isLoading: boolean
  inputClassName?: string
  validation?(reference: Reference): void
  customItemTemplate?: ReactNode | ((option: Reference) => ReactNode)
  showClearIcon?: boolean
}

export { AutoCompleteReferenceField }
