import { useQuery } from "@tanstack/react-query"
import { asReference, Reference } from "fhir"

import { useClient } from "api"
import { useLoginContext } from "security"

import { OrganizationApi } from "../types"

const useOrganizationsPartOf = (practitionerId?: string) => {
  const { search } = useClient()
  const { logedInPractitioner } = useLoginContext()
  const queryKey = ["organizations", practitionerId]

  const filters = new URLSearchParams({
    type: "organization-root",
    _sort: "name",
    _count: "all",
  })

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const bundle = await search(`organizations`, filters)

      const organizations = bundle.entry as OrganizationApi[]

      return organizations
    },

    enabled: !!logedInPractitioner.practitioner.id,
  })
  const { organizationsRoots, indexedOrgs } =
    data?.reduce<{ organizationsRoots: Reference[]; indexedOrgs: Record<string, Reference> }>(
      (prev, { organization }) => {
        const orgId = organization.id
        return {
          organizationsRoots: [...prev.organizationsRoots, asReference(organization)],
          indexedOrgs: { ...prev.indexedOrgs, ...(orgId ? { [orgId]: asReference(organization) } : {}) },
        }
      },
      { organizationsRoots: [], indexedOrgs: {} },
    ) ?? {}

  return { organizationsRoots, indexedOrgs, isLoading }
}

export { useOrganizationsPartOf }
