import { faBuilding, faCalendarCheck, faCreditCardFront } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { Device } from "fhir"

import { formatsByTypes } from "data"
import { formatDate, getBadgeColor } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

import { StackedListItemProps } from "../../components/StackedListItem"

const deviceModel = ({ device, onEdit, onDelete, isReader, locationName }: DeviceModelArgs): StackedListItemProps => ({
  leftData: [
    { lineItems: [{ name: "Device name", value: device.deviceName?.[0]?.name }] },
    ...(!isReader ? [{ lineItems: [{ name: "Room manufacturer", value: device.manufacturer }] }] : []),
    {
      lineItems: [
        ...(locationName ? [{ name: "Location", value: locationName, icon: faBuilding }] : []),
        ...(device.meta?.createdAt
          ? [
              {
                name: "Registered on",
                value:
                  device.meta?.createdAt &&
                  formatDate(parseISO(new Date(device.meta.createdAt).toISOString()), formatsByTypes.LONG_DATE),
                icon: faCalendarCheck,
              },
            ]
          : []),
        ...(device.modelNumber ? [{ name: "Model", value: device.modelNumber }] : []),
      ],
    },
  ],
  image: isReader ? <FontAwesomeIcon icon={faCreditCardFront} size="3x" /> : undefined,
  badge: getBadgeColor(device.status as string),
  menu: defaultEditRemoveMenu({ onEdit, onDelete }),
})

interface DeviceModelArgs {
  device: Device
  onEdit: () => void
  onDelete?: () => void
  isReader?: boolean
  locationName?: string
}

export { deviceModel }
