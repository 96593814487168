import { useEffect } from "react"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"

import {
  AddressField,
  AddressVerificationFeedback,
  BirthdateField,
  EmailField,
  GenderField,
  InputField,
  PractitionerApi,
  useScreenContext,
  useSmartyAddressVerificationContext,
  ValueSetIds,
} from "commons"
import { IDENTIFIER_CODE, identifiersCodeByType } from "data"
import { IdentifierContainer } from "identifier"
import { PractitionerRoleContainer } from "practitioner-role"
import { useValueSet } from "value-set"

const PractitionerForm = ({ showBirthDate = true }: Props) => {
  const {
    addressVerificationInfo,
    autoCompleteRecommendedAddress,
    bypassAddressValidation,
    updateAddressVerificationInfo,
  } = useSmartyAddressVerificationContext()
  const { initialValues, setFieldValue } = useFormikContext<PractitionerApi>()

  const { codes } = useValueSet({ valueSetId: ValueSetIds.PRACTITIONER_IDENTIFIERS, enabled: true })
  const practIdentifierTypes = codes?.filter((code) =>
    identifiersCodeByType.practitioner.includes(code.code as IDENTIFIER_CODE),
  )
  const prIdentifierTypes = codes?.filter((code) =>
    identifiersCodeByType.practitionerRole.includes(code.code as IDENTIFIER_CODE),
  )
  const { isSmallScreen } = useScreenContext()
  const setAddressValue = (field: string, value: unknown) => setFieldValue(field, value) as Promise<void>

  useEffect(() => {
    return () => {
      updateAddressVerificationInfo(undefined)
    }
  }, [])

  return (
    <div className="w-full flex justify-center">
      <div
        className={classNames("flex flex-col justify-between content-between", {
          "w-[80%] relative p-fluid grid lg:grid-cols-2 gap-4 lg:gap-5 mb-3 items-start": !isSmallScreen,
        })}
      >
        <div
          className={classNames("flex flex-col grow left-col p-3 gap-3 h-min top-0", {
            " justify-stretch": !isSmallScreen,
          })}
        >
          <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
            <legend>Basic Information</legend>
            <InputField field="practitioner.name[0].given[0]" label="First Name" />
            <InputField field="practitioner.name[0].given[1]" label="Middle Name" />
            <InputField field="practitioner.name[0].family" label="Last Name" className="col-span-2" />

            <InputField field="practitioner.name[0].prefix[0]" label="Prefix" />
            <InputField field="practitioner.name[0].suffix[0]" label="Sufix" />
          </fieldset>

          <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
            <GenderField field="practitioner.gender" label="Biological Sex" className="col-span-2" />
            {showBirthDate && (
              <BirthdateField field="practitioner.birthDate" label="Birthdate" className="col-span-2" />
            )}
          </fieldset>

          <div className="flex flex-col justify-between divide-y divide-gray-300">
            <div className="relative p-fluid grid gap-4">
              <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
                <legend>Address</legend>
                <AddressField context="PractitionerApi" parentFieldName="practitioner.address[0]" />
              </fieldset>
            </div>
            <AddressVerificationFeedback
              addressVerificationInfo={addressVerificationInfo}
              handleAutoCompleteRecommendedAddress={() =>
                autoCompleteRecommendedAddress?.(setAddressValue, "practitioner.address[0]")
              }
              handleBypassAddressValidation={bypassAddressValidation}
            />
          </div>

          <fieldset className="grid gap-5 p-3 mb-5">
            <legend>Telecom</legend>

            <EmailField
              field="practitioner.telecom[0].value"
              label="Email"
              initialValue={initialValues.practitioner?.telecom?.[0].value}
              validateDuplicate={true}
              isPractitionerRole
            />

            <InputField
              type="tel"
              mask="+1 (999) 999-9999"
              unmask={true}
              field="practitioner.telecom[1].value"
              label="Phone"
            />
          </fieldset>
        </div>
        <div className="flex flex-col grow right-col  p-3 gap-3 min-h-max">
          <fieldset className="grid gap-5 p-3 mt-5 border-2 rounded-lg">
            <PractitionerRoleContainer
              field="roles"
              label="Roles"
              prIdentifierTypes={prIdentifierTypes}
              isPractActive={initialValues.practitioner?.active ?? true}
            />
          </fieldset>
          <fieldset className="grid gap-5 p-3 mt-5 border-2 rounded-lg">
            <IdentifierContainer
              field="practitioner.identifier"
              avoidReplacement
              label="Identifiers"
              types={practIdentifierTypes}
            />
          </fieldset>
        </div>
      </div>
    </div>
  )
}

type Props = {
  showBirthDate?: boolean
}

export { PractitionerForm }
