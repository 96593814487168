import { faPlus, faSearch } from "@fortawesome/pro-solid-svg-icons"
import { faSitemap } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Organization, Reference } from "fhir"
import { FormikHelpers } from "formik"
import { Dialog } from "primereact/dialog"
import { FC, useCallback, useId, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  Button,
  DataContainerForm,
  GroupedList,
  LoadingView,
  MasterDetailView,
  SkeletonLoader,
  StackedListItem,
  useFiltersContext,
  useScreenContext,
  useSmartyAddressVerification,
  ViewHeader,
} from "commons"

import {
  useActiveDeactiveOrganization,
  useCreateOrganization,
  useExportOrganizationPatients,
  useOrganization,
  useOrganizations,
  useUpdateOrganization,
} from "../hooks"
import { OrganizationApi, FilterProps } from "../types"
import { OrganizationFilters } from "./OrganizationFilters"
import { OrganizationForm } from "./OrganizationForm"
import { OrganizationOnboardModal } from "./OrganizationOnboardModal"
import { OrganizationPaymentModal } from "./OrganizationPaymentModal"
import { orgModelBuilder } from "./orgModelBuilder"
import { initialValues, organizationValidationSchema, sanitize } from "./validations"

const OrganizationtList: FC<Props> = ({ organizationsRoots }) => {
  const {
    searchText,
    filters: { active, orgRoot },
    hasActiveFilters,
    onFilter,
    onClearFilters,
    onSearch,
  } = useFiltersContext<FilterProps>()

  const [org, setOrg] = useState<OrganizationApi | undefined>(undefined)
  const [params, setParams] = useSearchParams()
  const [showForm, setShowForm] = useState(false)
  const [orgIdToExportPatientsFrom, setOrgIdToExportPatientsFrom] = useState<string | undefined>(undefined)

  const { organizationGroups, isLoading, count, total, hasNextPage, fetchNextPage } = useOrganizations(
    searchText,
    active,
    orgRoot?.id,
  )

  const { organization, isLoading: isLoadingOrganization } = useOrganization(org?.organization.id)

  const { exportPatients, isExporting } = useExportOrganizationPatients(() => setOrgIdToExportPatientsFrom(undefined))

  const [showPaymentDialog, setShowPaymentDialog] = useState(false)
  const [showEnableDisableConfirm, setShowEnableDisableConfirm] = useState(false)
  const [showOnboardDialog, setShowOnboardDialog] = useState(false)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()

  const closeOrgForm = () => {
    params.delete("orgId")
    setParams(params)
    setOrg(undefined)
    setShowForm(false)
  }

  const { createOrganization } = useCreateOrganization(closeOrgForm)
  const { updateOrganization } = useUpdateOrganization(closeOrgForm)

  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const onSubmit = async (organization: Organization, formikHelpers?: FormikHelpers<Organization> | undefined) => {
    await checkAddress(organization.address![0], formikHelpers, () => {
      clearVerificationInfo()
      !organization.id ? createOrganization(sanitize(organization)) : updateOrganization(sanitize(organization))
    })
  }

  const hidePaymentDialog = () => {
    setShowPaymentDialog(false)
    setOrg(undefined)
  }

  const hideEnableDisableConfirm = () => {
    setShowEnableDisableConfirm(false)
    setOrg(undefined)
  }
  const navigate = useNavigate()

  const viewAdministration = (org: Organization) => {
    navigate(`/organizations/${org.id}/administration`)
  }

  const showEditForm = (organization?: Organization) => {
    setShowForm(true)
    setOrg({
      organization: {
        id: organization?.id,
      },
    })
  }
  const { isUpdating, updateOrganizationStatus } = useActiveDeactiveOrganization(hideEnableDisableConfirm)

  const handleShowStatusDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowEnableDisableConfirm(true)
  }

  const changeStatus = useCallback(() => {
    if (org?.organization) {
      updateOrganizationStatus(org.organization)
    }
  }, [org, updateOrganizationStatus])

  const handleShowPaymentDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowPaymentDialog(true)
  }

  const handleShowOnboardDialog = (org: OrganizationApi) => {
    setOrg(org)
    setShowOnboardDialog(true)
  }

  const handleExportPatients = (org: Organization) => {
    setOrgIdToExportPatientsFrom(org.id)
    exportPatients(org.id as string)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <ViewHeader
        isSmallViewport={isSmallScreen}
        viewTitle="Organizations"
        infoText={`Showing ${count} organizations of ${total} found`}
        showMenu={() => setSidebarCollapsed(false)}
      />
      <MasterDetailView
        onSearch={onSearch}
        hasActiveFilters={hasActiveFilters}
        loading={isLoading}
        isSmallViewport={isSmallScreen}
        containerClassName="h-full overflow-hidden"
        placeholder="Search organizations"
        searchText={searchText}
        headerAction={
          organizationsRoots?.length
            ? { label: "Add organization", icon: faPlus, command: () => setShowForm(true) }
            : undefined
        }
        filters={
          !showForm && (
            <OrganizationFilters
              initialValues={{ active, orgRoot }}
              roots={organizationsRoots}
              onSearch={onFilter}
              onClearFilters={onClearFilters}
              hasActiveFilters={hasActiveFilters}
              isSmallViewport={isSmallScreen}
            />
          )
        }
        headerText={!isSmallScreen ? `Showing ${count} organizations of ${total} found` : undefined}
        hiddenHeader={showForm}
      >
        {isLoading ? (
          loader()
        ) : (
          <DataContainerForm
            hasData={count > 0}
            showForm={showForm}
            showAddButton={false}
            formTitle="Organization"
            iconDataNotFound={faSitemap}
            formInitialValue={initialValues(organization?.organization)}
            validationSchema={organizationValidationSchema}
            onSubmit={onSubmit}
            onCancel={closeOrgForm}
            form={
              isLoadingOrganization ? (
                <LoadingView />
              ) : (
                <OrganizationForm organizationsRoots={organizationsRoots} isEditing={params.has("orgId")} />
              )
            }
            customAddButtonText="Add organization"
            subMessageDataNotFound="Get started by adding an organization"
            onButtonAddClick={() => setShowForm(true)}
            cancelButtonLabel="Close"
          >
            {!showForm && (
              <div className="flex flex-col overflow-auto grow pt-4">
                <InfiniteScroll
                  hasMore={hasNextPage}
                  loadMore={() => fetchNextPage()}
                  useWindow={false}
                  loader={loader()}
                >
                  <GroupedList
                    className="grow"
                    groups={organizationGroups}
                    renderItem={(organizationApi) => {
                      const isLoading = orgIdToExportPatientsFrom === organizationApi.organization.id && isExporting

                      return (
                        <StackedListItem
                          itemPadding
                          modelData={orgModelBuilder(
                            organizationApi,
                            () => {
                              showEditForm(organizationApi.organization)
                            },
                            handleShowStatusDialog,
                            handleShowOnboardDialog,
                            handleShowPaymentDialog,
                            viewAdministration,
                            handleExportPatients,
                            isLoading,
                          )}
                        />
                      )
                    }}
                    renderDivider={(name) => (
                      <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                        <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                      </div>
                    )}
                    renderEmptyState={() => (
                      <div className="flex flex-col items-center justify-center pt-10">
                        <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
                        <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
                      </div>
                    )}
                  />
                </InfiniteScroll>
                {showPaymentDialog && (
                  <OrganizationPaymentModal
                    onCancel={hidePaymentDialog}
                    show={showPaymentDialog}
                    org={org?.organization}
                  />
                )}

                <Dialog
                  header="Confirmation"
                  closable={true}
                  draggable={false}
                  visible={showEnableDisableConfirm}
                  className="w-full md:w-[70%] lg:w-[30%] m-2 "
                  onHide={hideEnableDisableConfirm}
                  footer={
                    <div className="mt-2">
                      <Button
                        label="Cancel"
                        disabled={isUpdating}
                        className="button-default"
                        onClick={hideEnableDisableConfirm}
                      />
                      <Button label="Accept" className="button-primary" onClick={changeStatus} loading={isUpdating} />
                    </div>
                  }
                >
                  <span>Are you sure you want to change the organization status?</span>
                </Dialog>

                {showOnboardDialog && (
                  <OrganizationOnboardModal
                    organization={org?.organization}
                    show={showOnboardDialog}
                    onCancel={() => {
                      setShowOnboardDialog(false)
                    }}
                  />
                )}
              </div>
            )}
          </DataContainerForm>
        )}
      </MasterDetailView>
    </>
  )
}

type Props = { organizationsRoots?: Reference[] }

export { OrganizationtList }
