import { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { FiltersContextProvider } from "commons"

import { FilterProps } from "../types"
import { PatientList } from "./PatientList"

const PatientsView: FC = () => {
  const [searchParams] = useSearchParams()
  const initialFilters = parseParamsToFilters(searchParams)

  return (
    <FiltersContextProvider initialFilters={initialFilters} setUrlFilters>
      <PatientList />
    </FiltersContextProvider>
  )
}

const DFAULT_FILTERS: FilterProps = {
  email: undefined,
  gender: undefined,
}

export { PatientsView }

const parseParamsToFilters = (searchParams: URLSearchParams): FilterProps => ({
  email: searchParams.get("email") ?? DFAULT_FILTERS.email,
  gender: searchParams.get("gender") ?? DFAULT_FILTERS.gender,
})
