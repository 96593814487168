import { useId, useState } from "react"
import { Sidebar } from "primereact/sidebar"
import { SubsNotification } from "fhir"
import { Dialog } from "primereact/dialog"
import { faBell } from "@fortawesome/pro-solid-svg-icons"

import {
  Button,
  EmptyMessage,
  InfiniteScroll,
  MasterDetailView,
  SkeletonLoader,
  StackedListContainer,
  useFiltersContext,
  useScreenContext,
  ViewHeader,
} from "commons"

import { SubsNotificationDetails } from "./SubsNotificationDetails"
import { FilterProps } from "../types"
import { useResendSubsNotification, useSubsNotifications } from "../hooks"
import { notificationModelBuilder } from "./notificationModelBuilder"
import { SubsNotificationFilters } from "./SubsNotificationFilter"

const SubsNotificationsListView = () => {
  const [showOverlayDetails, setShowOverlayDetails] = useState(false)
  const [subsNotification, setSubsNotification] = useState<SubsNotification>()
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()

  const {
    searchText,
    filters: { occurrence, status },
    hasActiveFilters,
    onFilter,
    onSearch,
    onClearFilters,
  } = useFiltersContext<FilterProps>()

  const { resenSubsNotification, isSending } = useResendSubsNotification(() => setSubsNotification(undefined))

  const { subsNotifications, count, total, isLoading, hasNextPage, fetchNextPage } = useSubsNotifications(
    searchText,
    status?.map((status) => status).join(","),
    occurrence,
  )

  const showDetails = (subsnotification: SubsNotification) => {
    setSubsNotification(subsnotification)
    setShowOverlayDetails(true)
  }
  const closeDetails = () => {
    setShowOverlayDetails(false)
    setSubsNotification(undefined)
  }

  const customSidebarTitle = (
    <span className="bg-white w-full">
      <h6 className="font-semibold">Notification</h6>
    </span>
  )

  const renderModalFooter = () => (
    <div className="mt-2">
      <Button label="Cancel" disabled={isSending} className="button-default" onClick={closeDetails} />
      <Button
        label="Send"
        className="button-primary"
        onClick={() => {
          resenSubsNotification(subsNotification?.id ?? "")
        }}
        loading={isSending}
      />
    </div>
  )

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <ViewHeader
        isSmallViewport={isSmallScreen}
        viewTitle="Notifications"
        infoText={`Showing ${count} notifications of ${total} found`}
        showMenu={() => setSidebarCollapsed(false)}
      />
      <MasterDetailView
        onSearch={onSearch}
        isSmallViewport={isSmallScreen}
        hasActiveFilters={hasActiveFilters}
        searchText={searchText}
        loading={isLoading}
        containerClassName="flex flex-col overflow-auto grow"
        headerText={!isSmallScreen ? `Showing ${count} notifications of ${total} found` : undefined}
        filters={
          <SubsNotificationFilters
            initialValues={{ status, occurrence }}
            onSearch={onFilter}
            onClearFilters={onClearFilters}
            hasActiveFilters={hasActiveFilters}
            isSmallViewport={isSmallScreen}
          />
        }
        placeholder="Search notifications"
      >
        {isLoading ? (
          loader()
        ) : !subsNotifications?.length ? (
          <EmptyMessage icon={faBell} message="No Notifications Found" subMessage={false} />
        ) : (
          <>
            <InfiniteScroll
              loadMore={() => {
                fetchNextPage?.()
              }}
              hasMore={hasNextPage}
              useWindow={false}
              loader={loader()}
              initialLoad={false}
            >
              <StackedListContainer
                itemPadding
                data={subsNotifications}
                itemModelBuilder={(item) => notificationModelBuilder(item, showDetails, setSubsNotification)}
              />
            </InfiniteScroll>

            <Dialog
              header="Confirmation"
              closable={false}
              draggable={false}
              visible={!!subsNotification && !showOverlayDetails}
              className="w-full md:w-[70%] lg:w-[30%] m-2 "
              onHide={() => setSubsNotification(undefined)}
              footer={renderModalFooter}
            >
              <span>Are you sure you want to resend this notification?</span>
            </Dialog>
          </>
        )}
        <Sidebar
          visible={showOverlayDetails}
          position={isSmallScreen ? "bottom" : "right"}
          style={isSmallScreen ? { height: "95%" } : { minWidth: "30%" }}
          header={customSidebarTitle}
          onHide={closeDetails}
        >
          <div className="relative h-full w-full">
            <SubsNotificationDetails subsNotification={subsNotification} />
          </div>
        </Sidebar>
      </MasterDetailView>
    </>
  )
}

export { SubsNotificationsListView }
