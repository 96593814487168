import { humanNameAsString } from "fhir"
import InfiniteScroll from "react-infinite-scroller"
import { useId, useState } from "react"
import { Sidebar } from "primereact/sidebar"
import { faUpload, faUserPlus } from "@fortawesome/pro-solid-svg-icons"

import {
  GroupedList,
  LoadingView,
  SkeletonLoader,
  StackedListItem,
  useScreenContext,
  ConfirmDialog,
  useFiltersContext,
  MasterDetailView,
  ViewHeader,
  EmptyMessage,
} from "commons"

import { useActiveDeactivePatient, useInvitePatient, usePatients } from "../hooks"
import { FilterProps, PatientInfo } from "../types"
import { PatientFilters } from "./PatientFilters"
import { patientModelBuilder } from "./patientModelBuilder"
import { PatientInvite } from "./PatientInvite"
import { PatientDetailView } from "./PatientDetailView"
import { PatientImport } from "./PatientImport"

const PatientList: React.FC = () => {
  const {
    searchText,
    filters: { email, gender },
    hasActiveFilters,
    onClearFilters,
    onFilter,
    onSearch,
  } = useFiltersContext<FilterProps>()
  const [showOverlayForm, setShowOverlayForm] = useState(false)
  const [patientInfo, setPatientInfo] = useState<PatientInfo | undefined>()
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showActiveDeactiveDialog, setShowActiveDeactiveDialog] = useState(false)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()

  const onCancel = () => {
    setPatientInfo(undefined)
    setShowInviteModal(false)
  }

  const customSidebarTitlebar = (
    <span className="bg-white w-full flex items-center space-x-2">
      <h6 className="font-semibold">Patient details</h6>
    </span>
  )
  const closeForm = () => {
    setPatientInfo(undefined)
    setShowOverlayForm(false)
  }
  const showPatientDetails = (patientInfo: PatientInfo) => {
    setPatientInfo(patientInfo)
    setShowOverlayForm(true)
  }

  const handleInviteModal = (patientInfo: PatientInfo) => {
    setPatientInfo(patientInfo)
    setShowInviteModal(true)
  }
  const handleActiveDeactivePatient = (patientInfo: PatientInfo) => {
    setPatientInfo(patientInfo)
    setShowActiveDeactiveDialog(true)
  }
  const hideActiveDeactiveDialog = () => {
    setShowActiveDeactiveDialog(false)
    setPatientInfo(undefined)
  }

  const { invitePatient, isInviting } = useInvitePatient(
    patientInfo?.patient.id ?? "",
    humanNameAsString(patientInfo?.patient.name?.[0]),
    onCancel,
  )
  const { updatePatientStatus, isUpdating: isChangingStatus } = useActiveDeactivePatient()

  const changeStatus = (pat: PatientInfo) => {
    const { patient } = pat
    if (patient) updatePatientStatus(patient)
  }
  const { patientsGroups, count, fetchNextPage, hasNextPage, isLoading, total } = usePatients(searchText, email, gender)

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <ViewHeader
        isSmallViewport={isSmallScreen}
        viewTitle="Patients"
        infoText={`Showing ${count} patients of ${total} found`}
        showMenu={() => setSidebarCollapsed(false)}
      />
      <MasterDetailView
        onSearch={onSearch}
        containerClassName="flex flex-col overflow-auto grow pt-4"
        hasActiveFilters={hasActiveFilters}
        isSmallViewport={isSmallScreen}
        loading={isLoading}
        placeholder="Search patients"
        searchText={searchText}
        headerText={!isSmallScreen ? `Showing ${count} patients of ${total} found` : undefined}
        headerAction={{ label: "import patients", icon: faUpload, command: () => setShowUploadModal(true) }}
        filters={
          <PatientFilters
            initialValues={{ gender, email }}
            hasActiveFilters={hasActiveFilters}
            isSmallViewport={isSmallScreen}
            onSearch={onFilter}
            onClearFilters={onClearFilters}
          />
        }
      >
        {isLoading ? (
          loader()
        ) : (
          <>
            <InfiniteScroll
              hasMore={hasNextPage}
              loadMore={() => fetchNextPage()}
              useWindow={false}
              loader={loader()}
              className="min-h-full"
            >
              <GroupedList
                className="grow"
                groups={patientsGroups}
                renderItem={(patientInfo) => {
                  return (
                    <StackedListItem
                      itemPadding
                      modelData={patientModelBuilder(
                        patientInfo,
                        handleInviteModal,
                        showPatientDetails,
                        handleActiveDeactivePatient,
                      )}
                    />
                  )
                }}
                renderDivider={(name) => (
                  <div className="bg-gray-50 px-6 py-1 border-t border-b first:border-t-0">
                    <p className="text-sm text-gray-500 font-medium capitalize">{name}</p>
                  </div>
                )}
                renderEmptyState={
                  <EmptyMessage
                    message="No patients found"
                    icon={faUserPlus}
                    subMessage="Get started by importing patients"
                    itemTitle="patient"
                    className="min-h-full content-center"
                    action={() => setShowUploadModal(true)}
                    actionIcon={faUpload}
                    actionText="Import patients"
                  />
                }
              />
            </InfiniteScroll>
            <PatientImport
              visible={showUploadModal}
              isSmallViewport={isSmallScreen}
              onHide={() => setShowUploadModal(false)}
            />
            {showInviteModal && (
              <PatientInvite
                pat={patientInfo as PatientInfo}
                showInviteDialog={showInviteModal}
                hideInviteDialog={onCancel}
                isInviting={isInviting}
                invitePatient={invitePatient}
              />
            )}
            {
              <ConfirmDialog
                visible={showActiveDeactiveDialog || isChangingStatus}
                onConfirm={() => changeStatus(patientInfo as PatientInfo)}
                hideDialog={hideActiveDeactiveDialog}
                isLoading={isChangingStatus}
                confirmText={`Do you want to ${
                  patientInfo?.patient?.active === false ? "activate" : "deactivate"
                } this patient?`}
              />
            }
            <Sidebar
              visible={showOverlayForm}
              position={isSmallScreen ? "bottom" : "right"}
              style={isSmallScreen ? { height: "95%" } : { minWidth: "30%" }}
              header={customSidebarTitlebar}
              onHide={closeForm}
            >
              {isLoading ? (
                <LoadingView />
              ) : (
                <div className="relative h-full pt-5 w-full">
                  {patientInfo && <PatientDetailView {...{ ...(patientInfo ?? {}) }}></PatientDetailView>}
                </div>
              )}
            </Sidebar>
          </>
        )}
      </MasterDetailView>
    </>
  )
}

export { PatientList }
