import { Form, Formik, FormikProps } from "formik"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { DropdownField, InputField, OrganizationAutocomplete, ValueSetIds } from "commons"
import { genders } from "data"
import { useValueSet } from "value-set"

import { FilterProps } from "../types"

const PractitionerFilters = ({ initialValues, hasActiveFilters, onSearch, onClearFilters }: Props) => {
  const { codes } = useValueSet({ valueSetId: ValueSetIds.PRACTITIONER_ROLE, enabled: true })
  const statusTypes = [
    { code: "true", display: "Active" },
    { code: "false", display: "Deactivated" },
  ]

  const renderForm = ({ isSubmitting, initialValues, values }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white gap-4">
      <InputField type="email" field="email" label="Email" className="w-full" inputClassName="text-sm" clearable />

      <OrganizationAutocomplete
        field="organization"
        label="Organization"
        inputClassName="text-sm w-full"
        className="w-full"
      />

      <DropdownField
        field="role"
        label="Role"
        options={codes ?? []}
        optionValue="code"
        optionLabel="display"
        className="w-full"
        inputClassName="text-sm"
        showClear
      />

      <DropdownField
        field="gender"
        label="Biological Sex"
        options={genders}
        className="w-full "
        inputClassName="text-sm"
        showClear
      />

      <DropdownField
        field="status"
        label="Status"
        optionLabel="display"
        options={statusTypes}
        className="w-full"
        inputClassName="text-sm"
        showClear
      />

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || !hasActiveFilters}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button
          type={JSON.stringify(initialValues) === JSON.stringify(values) ? "button" : "submit"}
          label="Search"
          loading={isSubmitting}
          className="p-button-sm button-primary"
        />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch} enableReinitialize>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { PractitionerFilters }
