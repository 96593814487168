import { PropsWithChildren } from "react"
import { Sidebar, SidebarPassThroughOptions } from "primereact/sidebar"
import { Button as PButton } from "primereact/button"
import { classNames } from "primereact/utils"

import { Button } from "../components/Buttons"

const Slideover = ({
  showSlide,
  onHide,
  showCloseIcon = false,
  dismissable = false,
  title,
  children,
  childrenContainerClassName = "px-4 sm:px-6 space-y-6 pb-6 pt-1",
  showCancel,
  showAccept,
  showButtons,
  cancelLabel = "Cancel",
  acceptLabel = "Accept",
  acceptLoading,
  acceptDisabled,
  onAccept,
  pt,
}: PropsWithChildren<SlideoverProps>) => {
  const isSmallScreen = window.innerWidth < 768
  return (
    <Sidebar
      visible={showSlide}
      position={isSmallScreen ? "bottom" : "right"}
      onHide={onHide}
      className={isSmallScreen ? "h-[95%] rounded-t-xl" : "md:w-1/2 lg:w-1/3"}
      maskClassName="slideover"
      dismissable={dismissable}
      pt={pt}
    >
      {title || showCancel || showAccept || showButtons || showCloseIcon ? (
        <div className="bg-white divide-gray-200 divide-y flex flex-col h-full  w-full">
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className={classNames("flex items-center", title ? "justify-between" : "justify-end py-2")}>
              {title && (
                <div className="px-4 sm:px-6 py-6">
                  <h2 className="text-lg font-semibold leading-6 text-gray-900">{title}</h2>
                </div>
              )}
              {showCloseIcon && (
                <PButton
                  icon="pi pi-times"
                  className="bg-white hover:bg-gray-200 border-0 text-gray-500 rounded-full w-8 h-8 mr-4 focus:primary-ring-shadow"
                  onClick={onHide}
                />
              )}
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto">
              <div className={childrenContainerClassName}>{children}</div>
            </div>
          </div>
          {(showButtons || showCancel || showAccept) && (
            <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
              {(showButtons || showCancel) && (
                <Button label={cancelLabel} buttonStyle="default" size="lg" onClick={onHide} />
              )}
              {(showButtons || showAccept) && (
                <Button
                  label={acceptLabel}
                  size="lg"
                  loading={acceptLoading}
                  disabled={acceptDisabled}
                  onClick={onAccept}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        children
      )}
    </Sidebar>
  )
}

export type SlideoverProps = {
  showSlide: boolean
  onHide(): void
  width?: string
  height?: string
  position?: "top" | "bottom" | "left" | "right" | undefined
  showCloseIcon?: boolean
  dismissable?: boolean
  title?: string
  className?: string
  childrenContainerClassName?: string
  showCancel?: boolean
  showAccept?: boolean
  showButtons?: boolean
  cancelLabel?: string
  acceptLabel?: string
  acceptLoading?: boolean
  acceptDisabled?: boolean
  onAccept?(): void
  pt?: SidebarPassThroughOptions
}

export { Slideover }
