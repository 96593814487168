import { FileUpload } from "primereact/fileupload"
import { ProgressBar } from "primereact/progressbar"
import { FC, useEffect, useMemo, useRef } from "react"

import { ImportProgressData } from "../types"

const ImportProgress: FC<Props> = ({ file, progress }) => {
  const fileInput = useRef<FileUpload>(null)

  useEffect(() => {
    fileInput.current?.setFiles([file])
  }, [file])

  const percentage = useMemo(
    () =>
      Math.floor(((progress.created + progress.updated + progress.failed + progress.skipped) / progress.total) * 100),
    [progress.total, progress.created, progress.failed, progress.skipped, progress.updated],
  )

  return (
    <>
      <FileUpload
        ref={fileInput}
        disabled
        className="p-button-sm"
        mode="basic"
        pt={{ basicButton: { className: "max-w-full" }, label: { className: "truncate" } }}
      />
      <div className="w-full flex flex-col gap-2">
        <ProgressBar
          value={percentage}
          pt={{
            value: { className: progress.failed ? "bg-red-500" : progress.skipped ? "bg-yellow-500" : "bg-primary" },
          }}
        />
        <div className="grid grid-flow-col gap-1 font-light text-xs">
          <span className="text-nowrap">Total records: {progress.total}</span>
          <span className="text-nowrap text-green-500 text-center">
            Successfull: {progress.created + progress.updated}
          </span>
          {!!progress.skipped && (
            <span className="text-nowrap text-yellow-500 text-center">Skipped: {progress.skipped}</span>
          )}
          <span className="text-nowrap p-error text-end">Failed: {progress.failed}</span>
        </div>
      </div>
      <div className="flex flex-col gap-2 divide-y text-sm grow overflow-y-auto">
        {progress.logs.toReversed().map((log, index) => (
          <div key={index} className="pt-3">
            <p title={log} className="truncate">
              {log}
            </p>
          </div>
        ))}
      </div>
    </>
  )
}

type Props = {
  file: File
  progress: ImportProgressData
}

export { ImportProgress }
