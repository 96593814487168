import { Navigate, Route, Routes } from "react-router-dom"

import { AdministrationContainer } from "administration"
import { OrdersView } from "orders"
import { OrganizationsView } from "organizations"
import { PatientsView } from "patients"
import { PractitionersView } from "practitioners"
import { SubnotificationsView } from "subnotifications"
import { useLogedInPractitioner, UserInformation } from "user"
import { UsersView } from "users"

const RoutesContainer: React.FC = () => {
  const { isRootAdmin } = useLogedInPractitioner()

  return (
    <Routes>
      <Route path="/" element={<Navigate to={"organizations"} />} />
      <Route path="practitioners">
        <Route index element={<PractitionersView />} />
      </Route>
      <Route path="patients">
        <Route index element={<PatientsView />} />
      </Route>
      <Route path="practitioner-profile">
        <Route path=":practitionerId" element={<UserInformation />} />
      </Route>
      <Route path="organizations">
        <Route index element={<OrganizationsView />} />
        <Route path=":organizationId/administration" element={<AdministrationContainer />} />
      </Route>
      <Route path="users">
        <Route index element={<UsersView />} />
      </Route>
      <Route path="orders">
        <Route index element={<OrdersView />} />
      </Route>
      {isRootAdmin && (
        <Route path="subnotifications">
          <Route index element={<SubnotificationsView />} />
        </Route>
      )}
    </Routes>
  )
}

export { RoutesContainer }
