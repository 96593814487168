import InfiniteScroll from "react-infinite-scroller"
import { useCallback, useId, useState } from "react"
import { User } from "fhir/fhir-extended"
import { Dialog } from "primereact/dialog"
import { Sidebar } from "primereact/sidebar"
import { faPaperPlane, faUserGroup } from "@fortawesome/pro-solid-svg-icons"

import {
  ConfirmDialog,
  SkeletonLoader,
  StackedListContainer,
  Button,
  useScreenContext,
  LoadingView,
  EmailCheckProvider,
  EmailCheckContext,
  DialogFormContainer,
  useFiltersContext,
  MasterDetailView,
  ViewHeader,
  EmptyMessage,
} from "commons"

import { Auth2faMethods, DialogTitle, FilterProps, PasswordData } from "../types"
import { UsersFilters } from "./UsersFilters"
import { userModelBuilder } from "./userModelBuilder"
import {
  useActiveDeactiveUser,
  useRequestResetPassword,
  useUsers,
  useRemove2faPhoneMethod,
  useRemove2faSoftwareMethod,
  useChangeEmail,
  useInviteUser,
  useChangePassword,
  useUsersReducer,
} from "../hooks"
import { UserDetailView } from "./UserDetailView"
import { SetUserEmail, UserInvite } from "../utils"

const UserList = () => {
  const {
    searchText,
    filters: { status, after, before },
    hasActiveFilters,
    onFilter,
    onSearch,
    onClearFilters,
  } = useFiltersContext<FilterProps>()
  const {
    showDialog,
    user,
    dialogTitle,
    content,
    initialValue,
    validateSchema,
    usersReset,
    setUser,
    handleInviteUser,
    handleEditEmail,
    handleChangePassword,
  } = useUsersReducer()

  const { users, isLoading, hasNextPage, fetchNextPage } = useUsers(searchText, status, after, before)
  const [showActiveDeactiveDialog, setShowActiveDeactiveDialog] = useState(false)
  const [showRequestResetPasswordDialog, setShowRequestResetPasswordDialog] = useState(false)
  const [auth2faDialog, setAuth2faDialog] = useState(Auth2faMethods.NONE)
  const { isSmallScreen, setSidebarCollapsed } = useScreenContext()
  const [showOverlayForm, setShowOverlayForm] = useState(false)

  const onCancel = () => {
    setShowActiveDeactiveDialog(false)
    setShowRequestResetPasswordDialog(false)
    setAuth2faDialog(Auth2faMethods.NONE)
    usersReset()
  }
  const { changeUserEmail, isChangingEmail } = useChangeEmail(onCancel)
  const { inviteUser, isInviting } = useInviteUser(onCancel)
  const { changePassword, isChangingPassword } = useChangePassword(onCancel)

  const changeEmail = (data: SetUserEmail) => {
    changeUserEmail(data)
  }

  const closeForm = () => {
    setUser(undefined)
    setShowOverlayForm(false)
  }

  const { activeDeactiveUser, isChangingStatus } = useActiveDeactiveUser(onCancel)
  const { requestResetPassword, isRequesting } = useRequestResetPassword(onCancel)
  const { remove2faPhone, isRemoving2faPhone } = useRemove2faPhoneMethod(onCancel)
  const { remove2faSoftware, isRemoving2faSoftware } = useRemove2faSoftwareMethod(onCancel)

  const handleChangeStatus = useCallback(() => {
    if (user) {
      activeDeactiveUser(user)
    }
  }, [user, activeDeactiveUser])

  const handleShowStatusDialog = (user: User) => {
    setUser(user)
    setShowActiveDeactiveDialog(true)
  }

  const handleRequestResetPassword = useCallback(() => {
    if (user) {
      requestResetPassword(user)
    }
  }, [user, requestResetPassword])

  const handleShowRequestResetPasswordDialog = (user: User) => {
    setUser(user)
    setShowRequestResetPasswordDialog(true)
  }
  const showUserDetails = (user: User) => {
    setUser(user)
    setShowOverlayForm(true)
  }

  const handleShow2faMethodDialog = (user: User, method: Auth2faMethods) => {
    setUser(user)
    setAuth2faDialog(method)
  }
  const customSidebarTitlebar = (
    <span className="bg-white w-full flex items-center space-x-2">
      <h6 className="font-semibold">User details</h6>
    </span>
  )

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  return (
    <>
      <ViewHeader isSmallViewport={isSmallScreen} viewTitle="Users" showMenu={() => setSidebarCollapsed(false)} />
      <MasterDetailView
        onSearch={onSearch}
        containerClassName="flex flex-col overflow-auto grow"
        filters={
          <UsersFilters
            initialValues={{ status, after, before }}
            hasActiveFilters={hasActiveFilters}
            isSmallViewport={isSmallScreen}
            onSearch={onFilter}
            onClearFilters={onClearFilters}
          />
        }
        isSmallViewport={isSmallScreen}
        loading={isLoading}
        searchText={searchText}
        placeholder="Search users"
        headerAction={{ label: "Invite user", icon: faPaperPlane, command: handleInviteUser, title: "invite user" }}
      >
        {isLoading ? (
          loader()
        ) : !users?.length ? (
          <EmptyMessage
            message="No users found"
            icon={faUserGroup}
            subMessage="Get started by invite users"
            itemTitle="user"
            action={handleInviteUser}
            actionIcon={faPaperPlane}
            actionText="Invite user"
          />
        ) : (
          <>
            <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
              <StackedListContainer
                itemPadding
                data={users}
                itemModelBuilder={(item) =>
                  userModelBuilder(
                    item,
                    showUserDetails,
                    handleShowStatusDialog,
                    handleShowRequestResetPasswordDialog,
                    handleChangePassword,
                    handleShow2faMethodDialog,
                    handleEditEmail,
                  )
                }
              />
            </InfiniteScroll>
            <Sidebar
              visible={showOverlayForm}
              position={isSmallScreen ? "bottom" : "right"}
              style={isSmallScreen ? { height: "95%" } : { minWidth: "30%" }}
              header={customSidebarTitlebar}
              onHide={closeForm}
            >
              {isLoading ? (
                <LoadingView />
              ) : (
                <div className="relative h-full pt-5 w-full">{user && <UserDetailView {...user} />}</div>
              )}
            </Sidebar>
          </>
        )}
        <Dialog
          header="Change status"
          visible={showActiveDeactiveDialog}
          className="w-full md:w-[70%] lg:w-[30%] m-2"
          onHide={onCancel}
          footer={
            <div className="mt-2">
              <Button label="Cancel" disabled={isChangingStatus} className="button-default" onClick={onCancel} />
              <Button
                label="Accept"
                className="button-primary"
                loading={isChangingStatus}
                disabled={isChangingStatus}
                onClick={handleChangeStatus}
              />
            </div>
          }
        >
          <span>Do you want to change account status to {user?.displayName}?</span>
        </Dialog>
        <ConfirmDialog
          confirmText="Are you sure you want to remove this 2FA method?"
          visible={auth2faDialog !== Auth2faMethods.NONE || isRemoving2faPhone || isRemoving2faSoftware}
          isLoading={isRemoving2faPhone}
          actionName="Remove"
          onConfirm={() => {
            if (user) auth2faDialog === Auth2faMethods.PHONE ? remove2faPhone(user) : remove2faSoftware(user)
          }}
          hideDialog={() => setAuth2faDialog(Auth2faMethods.NONE)}
        />
        <Dialog
          header="Request reset password"
          visible={showRequestResetPasswordDialog}
          className="w-full md:w-[70%] lg:w-[30%] m-2 "
          onHide={onCancel}
          footer={
            <div className="mt-2">
              <Button label="Cancel" disabled={isRequesting} className="button-default" onClick={onCancel} />
              <Button
                label="Accept"
                className="button-primary"
                loading={isRequesting}
                disabled={isRequesting}
                onClick={handleRequestResetPassword}
              />
            </div>
          }
        >
          <span>
            Do you want to request reset password for <span className="font-semibold">{user?.displayName}</span>?
          </span>
        </Dialog>
        <EmailCheckProvider>
          <EmailCheckContext.Consumer>
            {({ isEqualToInitialValue }) => (
              <DialogFormContainer
                title={dialogTitle}
                onCancel={onCancel}
                onSubmit={(data) => {
                  dialogTitle === DialogTitle.inviteUser
                    ? inviteUser(data as UserInvite)
                    : dialogTitle === DialogTitle.editEmail
                      ? changeEmail(data as SetUserEmail)
                      : changePassword(data as PasswordData)
                }}
                showForm={showDialog || isChangingEmail || isChangingPassword || isInviting}
                initialValue={initialValue}
                disableSave={isEqualToInitialValue}
                validationSchema={validateSchema}
              >
                {content}
              </DialogFormContainer>
            )}
          </EmailCheckContext.Consumer>
        </EmailCheckProvider>
      </MasterDetailView>
    </>
  )
}

export { UserList }
