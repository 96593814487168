import {
  asReference,
  getResources,
  getResourcesByTypeAsIndex,
  Organization,
  PractitionerRole,
  PractitionerRoleOrganization,
} from "fhir"
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { IClient, useClient } from "api"

import { practitionerRoleQueryKeys } from "../query-keys"

const usePractitionerRolePractitioner = (practId?: string) => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = practitionerRoleQueryKeys.practId(practId ?? "")

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(practId ? { practitioner: practId } : {}),
        _include: "organization:Organization",
      })
      const bundle = await search(`PractitionerRole`, filters, undefined, signal)
      const prs = getResources<PractitionerRole>(bundle, "PractitionerRole")
      const organizations = getResourcesByTypeAsIndex<Organization>(bundle, "Organization")

      return {
        prs,
        organizations,
      }
    },
    meta: { context: { queryKey, practId } },
    enabled: !!practId,
    throwOnError: true,
  })

  const { practitionerRoles } = useMemo(() => {
    const practitionerRoles =
      data?.prs.map((pr) => ({
        ...pr,
        organization: asReference(
          data.organizations[pr.organization?.id as string] ?? pr.organization,
        ) as PractitionerRoleOrganization,
      })) ?? []

    return { practitionerRoles }
  }, [data])

  return {
    roles: practitionerRoles,
    isLoading,
    isFetching,
  }
}

export { usePractitionerRolePractitioner }
