import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters, ParametersParameterArrayValue } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess, strUncapitalize } from "utils"

import { patientQueryKey } from "../query-keys"
import { ImportProgressRecord, UploadData } from "../types"
import { defaultProgressData } from "../data"

const useUploadPatients = ({
  onSucces,
  onError,
  onProgressUpdated,
}: {
  onSucces?: (vars: UploadData) => void
  onProgressUpdated: (_: ImportProgressRecord) => void
  onError?: () => void
}) => {
  const { uploadFormDataFile } = useClient()
  const queryClient = useQueryClient()

  const uploadPatientsFile = async ({
    file,
    organization,
    practitioner,
    overwritePatients,
    invitePatients,
  }: UploadData) => {
    const formData = new FormData()

    formData.append("organizationId", organization.id ?? "")
    formData.append("practitionerId", practitioner.id ?? "")
    formData.append("csvFile", file)
    formData.append("updatePatients", String(overwritePatients))
    formData.append("invitePatients", String(invitePatients))

    return uploadFormDataFile("patients/upload/from-csv", formData)
  }

  const {
    mutate: uploadPatients,
    isPending,
    data,
  } = useMutation({
    mutationFn: uploadPatientsFile,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
      onError?.()
    },
    onSuccess: async (response, vars) => {
      await queryClient.invalidateQueries({
        queryKey: patientQueryKey.all,
        refetchType: "all",
      })
      onSucces?.(vars)

      const reader = response.body?.getReader()
      const decoder = new TextDecoder("utf-8")
      let done = false
      let line = ""

      while (!done) {
        const readResult = await reader?.read()
        const { value, done: isDone } = readResult ?? { value: undefined, done: true }
        done = isDone
        const decodedLine = decoder.decode(value, { stream: true }).trimEnd()
        // Parse nested parameters adding ,
        if (decodedLine !== "" && decodedLine !== " ")
          line = decodedLine.replaceAll("}\n{", "},{").replaceAll("} {", "},{")

        // Handle data fragment
        const newParameters: Parameters[] = JSON.parse(`[${line}]`)
        newParameters?.forEach((param) => {
          const result = param?.parameter?.reduce((acc, param) => {
            const newValue = param.value?.[parameterMap[param.name]] ?? param.resource
            return { ...acc, ...(newValue ? { [strUncapitalize(param.name)]: newValue } : {}) }
          }, defaultProgressData)

          if (result) onProgressUpdated(result)
          if (result?.endOfResponse) done = true
        })
      }
    },
    onSettled: (_, error) => {
      if (!error) displayNotificationSuccess("Patients import finished successfully")
    },
  })
  return { uploadPatients, isUploading: isPending, data }
}

const parameterMap: { [key: string]: keyof ParametersParameterArrayValue } = {
  CreatedCount: "positiveInt",
  UpdatedCount: "positiveInt",
  RowCount: "positiveInt",
  FailedCount: "positiveInt",
  EndOfResponse: "boolean",
  ProcessedPatient: "Resource",
  Error: "string",
  SkippedCount: "positiveInt",
}

export { useUploadPatients }
