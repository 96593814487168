import { useQuery } from "@tanstack/react-query"
import { asReference, getResources, humanNameAsString, Practitioner, PractitionerRole, Reference } from "fhir"
import { useMemo } from "react"

import { IClient, useClient } from "api"
import { PRACTITIONER_ROLE } from "data"
import { isPRPractitioner } from "utils"

import { practitionerQueryKeys } from "../query-keys"

const usePractitionersOrganization = ({
  organizationId,
  onlyActivePracts = true,
  isNotPractitionerId,
  filter,
}: OrganizationPractitionersArgs) => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = practitionerQueryKeys.orgId(organizationId as string, isNotPractitionerId, filter)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _include: "PractitionerRole:practitioner:Practitioner",
        ...(onlyActivePracts ? { "practitioner:Practitioner.active:not": "false" } : {}),
        "active:not": "false",
        role: PRACTITIONER_ROLE.PRACTITIONER,
        ...(isNotPractitionerId ? { "practitioner:Practitioner.id:not": isNotPractitionerId } : {}),
        ...(filter ? { "practitioner:Practitioner.name:contains": filter } : {}),
      })

      const bundle = await search(`Organization/${organizationId}/PractitionerRole`, filters, undefined, signal)
      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const practRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")

      return { practitioners, practRoles, total: bundle?.total ?? 0 }
    },
    enabled: !!organizationId,
    meta: { context: { queryKey, organizationId } },
  })

  const { practitionerRolesRefs } = useMemo(() => {
    const { practitionerRolesRefs } =
      data?.practitioners.reduce<Reducer>(
        (prev, practitioner) => {
          const practitionerRoles = data?.practRoles.filter((pr) => pr.practitioner?.id === practitioner?.id)

          const practitionerRole = practitionerRoles.find(isPRPractitioner)
          const practitionerRoleRef = practitionerRole ? asReference(practitionerRole) : undefined

          return {
            practitionerRolesRefs: [
              ...prev.practitionerRolesRefs,
              {
                ...practitionerRoleRef,
                display: humanNameAsString(practitioner.name?.[0]),
              } as Reference,
            ],
          }
        },
        { practitionerRolesRefs: [] },
      ) ?? {}

    return { practitionerRolesRefs }
  }, [data?.practRoles, data?.practitioners])

  const { practitionerRefs } =
    data?.practitioners.reduce<{ practitionerRefs: Reference[] }>(
      (prev, practitioner) => {
        return {
          practitionerRefs: [...prev.practitionerRefs, asReference(practitioner)],
        }
      },
      { practitionerRefs: [] },
    ) ?? {}

  const practitioners = useMemo(
    () =>
      data?.practitioners.reduce(
        (acc, pract) => {
          if (pract.id) return { ...acc, [pract.id]: pract }

          return acc
        },
        {} as Record<string, Practitioner>,
      ),
    [data],
  )

  return {
    practitionerRolesRefs: practitionerRolesRefs,
    practitioners,
    practitionerRefs,
    total: data?.total,
    isLoading,
    isFetching,
  }
}

interface OrganizationPractitionersArgs {
  organizationId?: string
  filterByIdentifier?: string
  onlyActivePracts?: boolean
  isNotPractitionerId?: string
  filter?: string
}

type Reducer = {
  practitionerRolesRefs: Reference[]
}

export { usePractitionersOrganization }
