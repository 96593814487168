import { FC, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Reference } from "fhir"
import { useMountEffect } from "primereact/hooks"

import { useOrganization } from "organizations"
import { FiltersContextProvider, SkeletonLoader } from "commons"

import { FilterProps } from "../types"
import { PractitionerList } from "./PractitionerList"

const PractitionersView: FC = () => {
  const [searchParams] = useSearchParams()
  const [initialRender, setInitialRender] = useState(true)

  const organizationId = searchParams.get("organizationId")

  const { organizationRef, isLoading: isLoadingOrg } = useOrganization(organizationId ?? undefined, initialRender)

  useMountEffect(() => setInitialRender(false))

  if (isLoadingOrg)
    return (
      <>
        <SkeletonLoader loaderType="one-line" repeats={1} />
        <SkeletonLoader loaderType="form-two-cols" repeats={1} />
      </>
    )

  const initialFilters = parseParamsToFilters(searchParams, organizationRef)

  return (
    <FiltersContextProvider
      initialFilters={initialFilters}
      setUrlFilters
      filtersKeysValueMap={{
        status: (val) => (val === "true" ? "active" : val === "false" ? "deactivated" : undefined),
        organization: (org) => (org as Reference | undefined)?.id,
      }}
      filtersKeysNameMap={{ organization: "organizationId" }}
    >
      <PractitionerList />
    </FiltersContextProvider>
  )
}

const DFAULT_FILTERS: FilterProps = {
  email: undefined,
  gender: undefined,
  role: undefined,
  organization: undefined,
  status: undefined,
}

export { PractitionersView }

const parseParamsToFilters = (searchParams: URLSearchParams, organizationRef?: Reference): FilterProps => ({
  email: searchParams.get("email") ?? DFAULT_FILTERS.email,
  gender: searchParams.get("gender") ?? DFAULT_FILTERS.gender,
  status:
    searchParams.get("status") === "active"
      ? "true"
      : searchParams.get("status") === "deactivated"
        ? "false"
        : DFAULT_FILTERS.status,
  organization: searchParams.get("organizationId")
    ? organizationRef ?? ({ id: searchParams.get("organizationId"), resourceType: "Organization" } as Reference)
    : DFAULT_FILTERS.organization,
  role: searchParams.get("role") ?? DFAULT_FILTERS.role,
})
