import { faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MenuItem } from "primereact/menuitem"

const defaultEditRemoveMenu = ({ onEdit, onDelete }: DefaultEditRemoveMenuArgs): MenuItem[] => {
  return [
    ...(onEdit
      ? [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" />,
            command: onEdit,
          },
        ]
      : []),
    ...(onDelete
      ? [
          {
            label: "Delete",
            icon: <FontAwesomeIcon icon={faTrashCan} size="sm" />,
            command: onDelete,
          },
        ]
      : []),
  ]
}

interface DefaultEditRemoveMenuArgs {
  onEdit?: () => void
  onDelete?: () => void
}

export { defaultEditRemoveMenu }
