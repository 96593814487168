import { faFile } from "@fortawesome/pro-regular-svg-icons"
import { faCalendarDays, faSdCards } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Attachment } from "fhir"
import { ErrorMessage, Field, FieldProps } from "formik"
import { FileUpload, FileUploadHeaderTemplateOptions, ItemTemplateOptions } from "primereact/fileupload"
import { ProgressBar } from "primereact/progressbar"
import { classNames } from "primereact/utils"
import { FC, useCallback, useRef, useState } from "react"

import { formatsByTypes } from "data"
import { bytesToMegaBytes, formatDate } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

import { StackedListItem } from "../components/StackedListItem"
import { useAzureContainer } from "../hooks"
import { MenuStyles } from "../types"

const ONE_MB = 1048576 // in bytes

const FileUploaderField: FC<Props> = ({
  field,
  azureContainer,
  label,
  accept = "application/pdf",
  className,
  disabled,
  maxFileSize,
  mode = "basic",
  buttonLabel = "Choose",
  autoupload,
  validation,
}) => {
  const fileInput = useRef<FileUpload>(null)
  const [totalSize, setTotalSize] = useState(0)
  const clearFiles = () => fileInput.current?.clear()
  const { uploadFile } = useAzureContainer(azureContainer ?? "")

  const handleFileSelect =
    (name: string, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>
    (event: { files: File[] }) => {
      const file = event.files[0]
      const { name: fileName, size: fileSize, type: fileType } = file
      if (autoupload)
        uploadFile(file).then((url) => {
          setFieldValue(name, { url, size: fileSize, title: fileName, contentType: fileType } as Attachment)
        })
      else setFieldValue(name, file)
      setTotalSize(fileSize)
    }

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File
    return (
      <StackedListItem
        modelData={{
          leftData: [
            { lineItems: [{ name: "Title", value: file.name ?? "Unspecified", icon: faFile }] },
            {
              lineItems: [
                {
                  name: "Created",
                  value: formatDate(new Date(), formatsByTypes.LONG_DATETIME),
                  icon: faCalendarDays,
                },
                {
                  name: "Size",
                  value: fileInput.current?.formatSize(totalSize).toString() ?? "0 B",
                  icon: faSdCards,
                },
              ],
            },
          ],
          menu: defaultEditRemoveMenu({ onDelete: () => onTemplateRemove(file, props.onRemove) }),
          menuStyle: MenuStyles.ExternalAction,
        }}
        className="w-full"
      />
    )
  }
  const headerTemplate = useCallback(
    (options: FileUploadHeaderTemplateOptions) => {
      const { chooseButton, className } = options
      const value = (totalSize / (maxFileSize ?? 1)) * 100

      const formatedValue = fileInput && fileInput.current ? fileInput.current.formatSize(totalSize) : "0 B"
      return (
        <div className={classNames("bg-slate-100/75 p-2 flex items-center", className)}>
          {chooseButton}
          <div className="flex items-center justify-end gap-3 ml-auto text-sm">
            <span className="text-right text-sm">
              {formatedValue} {maxFileSize && `/ ${bytesToMegaBytes(maxFileSize)}MB`}
            </span>
            <ProgressBar
              value={value}
              showValue={false}
              pt={{ value: { className: "bg-secondary/50" } }}
              className="text-primary w-32 h-4"
            ></ProgressBar>
          </div>
        </div>
      )
    },
    [totalSize],
  )

  const emptyTemplate = () => {
    return (
      <div className="flex flex-col space-y-3 py-3 items-center border-dashed rounded-lg border-2 border-slate-300">
        <FontAwesomeIcon icon={faFile} size="lg" />
        <span className="text-slate-500">Drag and Drop Files Here</span>
      </div>
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onTemplateRemove = (file: File, callback: Function) => {
    setTotalSize(totalSize - file.size)
    callback()
  }

  return (
    <Field name={field} validation={validation}>
      {({
        field: { name },
        meta: { touched, error },
        form: { setFieldValue, setFieldError, setFieldTouched },
      }: FieldProps) => {
        const addValidationError = (f: File) => {
          if (maxFileSize && f.size > maxFileSize) {
            clearFiles()
            setTotalSize(0)
            setFieldError(name, `Invalid file. Max file size is ${Math.floor(maxFileSize / ONE_MB)}MB.`)
          }

          setFieldTouched(name, true, false)
        }

        return (
          <div className={classNames("field flex flex-col relative", className)}>
            {label && (
              <label htmlFor={name} className="text-sm font-medium text-gray-700 mb-2">
                {label}
              </label>
            )}
            <FileUpload
              id={name}
              name={name}
              accept={accept}
              maxFileSize={maxFileSize}
              disabled={disabled}
              customUpload
              ref={fileInput}
              uploadHandler={clearFiles}
              onSelect={handleFileSelect(name, setFieldValue)}
              onValidationFail={addValidationError}
              mode={mode}
              invalidFileSizeMessageDetail=""
              className={classNames("p-button-sm", {
                "p-invalid": touched && error,
                custom_advanced: mode === "advanced",
              })}
              headerTemplate={mode === "advanced" ? headerTemplate : undefined}
              itemTemplate={mode === "advanced" ? itemTemplate : undefined}
              chooseOptions={{
                icon: "pi pi-fw pi-plus",
                iconOnly: false,
                className: "btn-primary p-button-sm bg-primary outline-none border-none",
                label: buttonLabel,
              }}
              emptyTemplate={mode === "advanced" ? emptyTemplate : undefined}
              pt={{ basicButton: { className: "max-w-full" }, label: { className: "truncate" } }}
            />

            <div className="flex items-start p-error h-2 mt-1">
              <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

type Props = {
  field: string
  label?: string
  accept?: "application/pdf" | "image/*" | "text/csv"
  className?: string
  disabled?: boolean
  maxFileSize?: number
  mode?: "basic" | "advanced"
  buttonLabel?: string
  validation?: (val: File | Attachment | undefined) => string | undefined
} & OptionalUpload

type OptionalUpload =
  | {
      autoupload: true
      azureContainer: string
    }
  | { autoupload?: false; azureContainer?: string }

export { FileUploaderField }
