import { getFirstEmail, humanNameAsString } from "fhir"
import pluralize from "pluralize"

import { ImportProgressData, ImportProgressRecord } from "./types"

const handleUpdateProgress = (currentProgress: ImportProgressData | undefined, lastRecord: ImportProgressRecord) => {
  const patientName = humanNameAsString(lastRecord.processedPatient?.name?.[0])
  const patientEmail = getFirstEmail(lastRecord.processedPatient?.telecom)
  const newLog = !lastRecord.endOfResponse
    ? (currentProgress?.failed ?? 0) < lastRecord.failedCount
      ? (lastRecord.error as string)
      : (currentProgress?.created ?? 0) < lastRecord.createdCount
        ? `Patient ${patientName} with email ${patientEmail} imported successfully`
        : (currentProgress?.updated ?? 0) < lastRecord.updatedCount
          ? `Patient ${patientName} with email ${patientEmail} updated successfully`
          : (currentProgress?.skipped ?? 0) < lastRecord.skippedCount
            ? `Patient skipped due to possible duplicated record`
            : `Found ${lastRecord.rowCount} ${pluralize("patient", lastRecord.rowCount)} to import`
    : undefined

  const data: ImportProgressData = {
    ...(currentProgress ?? {}),
    lastRecord,
    created: lastRecord.createdCount,
    updated: lastRecord.updatedCount,
    failed: lastRecord.failedCount,
    total: lastRecord.rowCount,
    skipped: lastRecord.skippedCount,
    logs: [...(currentProgress?.logs ?? []), ...(newLog ? [newLog] : [])],
    importedPatients: [
      ...(currentProgress?.importedPatients ?? []),
      ...(lastRecord.processedPatient ? [lastRecord.processedPatient] : []),
    ],
  }

  return data
}

export { handleUpdateProgress }
