import { faBars } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, ReactNode } from "react"

const ViewHeader: FC<Props> = ({ isSmallViewport, viewTitle, infoText, showMenu }) => {
  if (!isSmallViewport) return null
  return (
    <header className="inline-flex w-full justify-between items-center p-5">
      <FontAwesomeIcon icon={faBars} size="lg" className="cursor-pointer" onClick={showMenu} />
      <div className="w-full flex flex-col items-end">
        <h1 className="text-lg font-semibold">{viewTitle}</h1>
        <span className="text-gray-400 text-sm">{infoText}</span>
      </div>
    </header>
  )
}

type Props = {
  isSmallViewport?: boolean
  showMenu(): void
  infoText?: string | ReactNode
  viewTitle?: string
}

export { ViewHeader }
