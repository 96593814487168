import { Location, asReference, codeableConceptAsString } from "fhir"
import { faPlus } from "@fortawesome/pro-light-svg-icons"

import {
  AutocompleteCodeableConceptField,
  Button,
  DataContainerSlideoverForm,
  InputField,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
  useScreenContext,
  ValueSetIds,
} from "commons"
import { useOrganizationContext } from "organizations"
import { getBadgeColor } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

import { useCreateRoom, useDeleteRoom } from "../hooks"
import { getRoomInitialValues } from "./validations"

const Rooms = () => {
  const { rooms, currentOrganization, location } = useOrganizationContext()
  const { showSlide, initialValue, deleteIndex, add, edit, setDeleteIndex, reset } = useCrudReducer({
    defaultEntity: getRoomInitialValues(asReference(currentOrganization), asReference(location as Location)),
  })
  const { isSmallScreen } = useScreenContext()
  const { createRoom } = useCreateRoom(reset)
  const { removeRoom, isDeleting } = useDeleteRoom()

  const onSubmit = (room: Location) => createRoom(room)

  return (
    <DataContainerSlideoverForm
      formTitle="Room"
      height="calc(100% - 8rem)"
      showSlide={showSlide}
      hasData={rooms.length > 0}
      onButtonAddClick={add}
      formInitialValue={initialValue}
      form={<RoomForm />}
      onSubmit={onSubmit}
      onCancel={reset}
      showAddButton={!isSmallScreen}
      deleteSupport={{
        showDelete: !!deleteIndex,
        isDeleting,
        onConfirmDelete: () => removeRoom(deleteIndex as string),
        onCancelDelete: () => setDeleteIndex(undefined),
      }}
    >
      <div>
        {isSmallScreen && (
          <div className="flex flex-col items-end mb-2">
            <Button label="Add" icon={faPlus} className="button-primary sm:text-sm w-20 h-8" onClick={add} />
          </div>
        )}
      </div>
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={rooms}
          itemPadding
          itemModelBuilder={(item) =>
            roomModel(
              item,
              () => edit(item),
              () => setDeleteIndex(item.id as string),
            )
          }
        />
      </div>
    </DataContainerSlideoverForm>
  )
}

const RoomForm = () => (
  <div className="flex flex-col space-y-4">
    <InputField field="name" label="Name" validation={(value) => (!value ? "Name is required" : undefined)} />
    <InputField field="description" label="Description" />
    <AutocompleteCodeableConceptField
      field="type[0]"
      label="Type"
      valueSetId={ValueSetIds.SERVICE_DELIVERY_LOCATION_TYPE}
      className="p-fluid"
    />
  </div>
)

const roomModel = (room: Location, onEdit: () => void, onDelete: () => void): StackedListItemProps => ({
  leftData: [
    { lineItems: [{ name: "Room name", value: room.name }] },
    { lineItems: [{ name: "Room description", value: room.description }] },
    {
      lineItems: [
        { name: "Room type", value: room.type?.[0] ? codeableConceptAsString(room.type?.[0]) : "Unknow type" },
      ],
    },
  ],
  badge: getBadgeColor(room.status as string),
  menu: defaultEditRemoveMenu({ onEdit, onDelete }),
})

export { Rooms }
