import { Form, Formik, FormikProps } from "formik"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { Button, DateField, MultiSelectField } from "commons"
import { subsNotificationType } from "data"

import { FilterProps } from "../types"

const SubsNotificationFilters = ({ initialValues, hasActiveFilters, onSearch, onClearFilters }: Props) => {
  const renderForm = ({ isSubmitting, initialValues, values }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <MultiSelectField
        field="status"
        label="Status"
        optionLabel="display"
        options={subsNotificationType}
        optionValue="code"
        className="grid-cols-10 w-full"
        inputClassName="text-sm"
      />
      <DateField label="Occurrence" field="occurrence" inputClassName="text-sm pl-2" maxDate={new Date()} />

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={!hasActiveFilters || isSubmitting}
          icon={faFilterSlash}
          onClick={onClearFilters}
          className="p-button-sm mr-3"
          buttonStyle="default"
        />
        <Button
          type={JSON.stringify(initialValues) === JSON.stringify(values) ? "button" : "submit"}
          label="Search"
          loading={isSubmitting}
          className="p-button-sm button-primary"
        />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues ?? {}} onSubmit={onSearch} enableReinitialize>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { SubsNotificationFilters }
