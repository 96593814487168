import { Form, Formik, FormikProps } from "formik"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { Button, DateField, DropdownField } from "commons"

import { FilterProps } from "../types"

const UsersFilters = ({ initialValues, hasActiveFilters, onSearch, onClearFilters }: Props) => {
  const statusTypes = [
    { code: "true", display: "Active" },
    { code: "false", display: "Deactivated" },
  ]
  const renderForm = ({ isSubmitting, initialValues, values }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        field="status"
        label="Status"
        optionLabel="display"
        options={statusTypes}
        inputClassName="text-sm pl-2"
        showClear
      />
      <DateField
        label="Created after"
        field="after"
        className="mr-1"
        inputClassName="text-sm pl-2"
        maxDate={new Date()}
      />
      <DateField
        label="Created before"
        field="before"
        className="ml-1"
        inputClassName="text-sm pl-2"
        maxDate={new Date()}
      />

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || !hasActiveFilters}
          icon={faFilterSlash}
          onClick={onClearFilters}
          className="p-button-sm mr-3"
          buttonStyle="default"
        />
        <Button
          type={JSON.stringify(initialValues) === JSON.stringify(values) ? "button" : "submit"}
          label="Search"
          loading={isSubmitting}
          className="p-button-sm button-primary"
        />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch} enableReinitialize>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { UsersFilters }
