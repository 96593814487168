import { Field, FieldProps, ErrorMessage } from "formik"
import { FC } from "react"
import { Dropdown, DropdownProps } from "primereact/dropdown"
import { Reference } from "fhir"
import { classNames } from "primereact/utils"

const ReferenceDropdownField: FC<Props> = ({
  field,
  options,
  label,
  className,
  horizontal,
  inputClassName,
  optionLabel = "display",
  readonly,
  disabled,
  filterBy = "display",
  allowClear = false,
  isLoading,
  validation,
  ...dropdownProps
}) => (
  <Field name={field} validate={validation}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div
        className={classNames(
          "field space-y-2 relative",
          horizontal ? "inline-flex justify-between" : "flex flex-col",
          className,
        )}
      >
        {label && (
          <label
            htmlFor={name}
            className={classNames("text-sm font-medium text-gray-700 mt-3", { "mr-3 mb-0 mt-2": horizontal })}
          >
            {label}
          </label>
        )}

        <Dropdown
          id={name}
          name={name}
          optionLabel={optionLabel}
          options={options}
          filterBy={filterBy}
          resetFilterOnHide={true}
          onChange={onChange}
          value={value}
          showClear={allowClear && !isLoading}
          readOnly={readonly}
          disabled={disabled}
          dataKey="id"
          className={classNames(
            "p-inputtext-sm",
            {
              "p-invalid": touched && error,
              horizontal,
            },
            inputClassName,
          )}
          loading={isLoading}
          {...dropdownProps}
        />

        <ErrorMessage name={field}>{(msg) => <small className="p-error absolute -bottom-6">{msg}</small>}</ErrorMessage>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  options?: Reference[]
  label?: string
  className?: string
  filterBy?: string
  horizontal?: boolean
  inputClassName?: string
  optionLabel?: string
  readonly?: boolean
  disabled?: boolean
  allowClear?: boolean
  isLoading?: boolean
  validation?(value: Reference): void
} & DropdownProps

export { ReferenceDropdownField }
