export enum SYSTEM_VALUES {
  // MEDICATION
  DRUG_CHARACTERISTIC = "http://chartedhealth.com/fhir/medicationknowledge-characteristic",
  MK_PACKAGE_TYPE = "http://chartedhealth.com/fhir/medicationknowledge-packaging-quantity-unit",
  VS_INGREDIENT = "http://chartedhealth.com/fhir/ValueSet/ingredient",
  MEDICATION_REQUEST_CATEGORY = "http://chartedhealth.com/fhir/medication-request-category",
  LIFEFILE_MEDICATION = "http://lifefile.com/fhir/identifiers/medication",
  LOINC = "http://loinc.org",
  MYEVEXIAS_MEDICATION = "http://myevexias.com/medication/identifier",
  MEDICATION_NDC = "https://dps.fda.gov/ndc",
  UNITS_MEASURE = "http://unitsofmeasure.org",
  VS_ROUTE_CODE = "http://hl7.org/fhir/ValueSet/route-codes",
  MED_FROM_CODES = "http://hl7.org/fhir/ValueSet/medication-form-codes",
  PUBLIC_MEDICATION = "http://chartedhealth.com/fhir/identifiers/public/medication",
  MEDICATION_REASON_CODE = "http://terminology.hl7.org/CodeSystem/reason-medication-given",
  BODY_SITES = "http://chartedhealth.com/fhir/body-sites",
  SKU = "http://chartedhealth.com/fhir/identifiers/sku",

  //PAYMENT
  INVOICE_INDENTIFIER = "http://chartedhealth.com/fhir/payment/invoice-identifier",
  FEE = "http://chartedhealth.com/fhir/payment/fee",
  PRODUCT_FEE = "http://chartedhealth.com/fhir/payment/product-fee",
  PRODUCT_TYPE = "http://chartedhealth.com/fhir/product-type",
  SERVICE_FEE = "http://chartedhealth.com/fhir/service-fee",
  DISCOUNT = "http://chartedhealth.com/fhir/payment/discount",
  CPOE_ACTION = "http://chartedhealth.com/fhir/cpoe/action",
  CPOE_ACTION_GROUP = "http://chartedhealth.com/fhir/cpoe/action-group",
  EXTERNAL_AGENT = "http://chartedhealth.com/fhir/external-agents",
  STRIPE = "http://chartedhealth.com/fhir/payment/stripe",
  STRIPE_READER = "http://chartedhealth.com/fhir/reader/stripe",
  ACCOUNT_SETUP = "http://chartedhealth.com/fhir/payment/account-setup",

  //SHIPPING METHODS
  SHIPPING_METHOD = "http://chartedhealth.com/fhir/shipping-method",
  SHIPPING_METHOD_DEFAULT = "http://chartedhealth.com/fhir/shipping-method-default",

  //PATIENT RELATED
  CONTEXT_TYPE = "http://chartedhealth.com/fhir/usage-context-type",
  GENDER = "http://hl7.org/fhir/ValueSet/administrative-gender",
  COVERAGE_TYPE = "http://chartedhealth.com/fhir/coverage-type",
  COVERAGE_CLASS = "http://terminology.hl7.org/CodeSystem/coverage-class",
  PATIENT_DOCUMENT_CATEGORY = "http://chartedhealth.com/fhir/ch-patient-document-category",
  HEALTH_GORILLA_COMMON_WELL = "http://chartedhealth.com/fhir/healthgorilla/common-well",
  SUBSCRIBER_RELATIONSHIP = "http://terminology.hl7.org/CodeSystem/subscriber-relationship",
  SMARTWAIVER = "http://Smartwaiver.com/templates",

  //ORGANIZATION RELATED
  LOCATION_PHYSICAL_TYPE = "http://terminology.hl7.org/CodeSystem/location-physical-type",
  EXTERNAL_IDENTIFIER = "http://chartedhealth.com/fhir/external-identifier",
  LIFEFILE_PRACTICE = "http://lifefile.com/fhir/identifiers/practice",
  LIFEFILE_PRACTITIONER = "http://lifefile.com/fhir/identifiers/practitioner",
  ORGANIZATION_DS_IDENTIFIER = "http://dosespot.com/practice/identifier",
  PRACTITIONER_DS_IDENTIFIER = "http://dosespot.com/practitioner/identifier",
  ORGANIZATION_TYPE = "http://chartedhealth.com/fhir/organization-type",

  //SERVICE REQUEST RELATED
  SERVICE_REQUEST_TYPE = "http://chartedhealth.com/fhir/service-request-type",
  ORDER_STATUS = "http://chartedhealth.com/fhir/order-status",
  ORDER_CANCEL_MODE = "http://chartedhealth.com/fhir/order/cancel-mode",
  OBSERVATION_CATEGORY = "http://terminology.hl7.org/CodeSystem/observation-category",
  OBSERVATION_INTERPRETATION = "http://metaboliccode.com/fhir/algorithm/observation-interpretation",
  DOCUMENT_REFERENCE_TYPE = "http://chartedhealth.com/fhir/document-reference-type",
  ACME_CODE_TEST = "http://acme.org/lab/codes/tests",
  ORDER_DETAIL_TYPE = "http://chartedhealth.com/fhir/order-detail-type",

  //CARE PLAN
  CARE_PLAN_OUTCOME = "http://chartedhealth.com/fhir/care-plan-activity-outcome",
  PLAN_ACTION = "http://chartedhealth.com/fhir/plans/action",

  PROCEDURE_CONFIGURATION = "http://chartedhealth.com/fhir/procedures/configuration",
  PROCEDURE_CONFIGURATION_DOSAGE = "http://chartedhealth.com/fhir/procedures/configuration/configure-dosage",
  PROCEDURE_TYPE = "http://chartedhealth.com/fhir/procedure-types",

  //COMMUNICATION
  COMMUNICATION_CATEGORY = "http://terminology.hl7.org/CodeSystem/communication-category",
  SENDGRID = "https://api.sendgrid.com/v3/",

  //FHIR
  V3_ACTCODE = "http://terminology.hl7.org/CodeSystem/v3-ActCode",
  V3_ACTENCOUNTERCODE = "http://hl7.org/fhir/ValueSet/v3-ActEncounterCode",
  V3_GTSABB = "https://terminology.hl7.org/CodeSystem-v3-GTSAbbreviation.html",
  V2_0074 = "http://terminology.hl7.org/CodeSystem/v2-0074",
  V2_0276 = "http://terminology.hl7.org/CodeSystem/v2-0276",
  V2_0203 = "http://terminology.hl7.org/CodeSystem/v2-0203",

  B2C = "http://chartedhealth.com/fhir/b2c-identifiers/users",

  TASK_CODE = "http://chartedhealth.com/fhir/task-code",

  SNOMED_SCT = "http://snomed.info/sct",
  NPI = "http://hl7.org/fhir/sid/us-npi",

  SETTING_DEFINITION_FLAG = "http://chartedhealth.com/fhir/setting-definition-flags",

  TEMPORARY_CODES = "http://chartedhealth.com/fhir/temporary-codes",
  RELATED_PRACTITIONER = "http://chartedhealth.com/CodeSystem/related-practitioner-type",
}
