import { useMutation } from "@tanstack/react-query"

import { IClient, useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess, openLinkInNewTab } from "utils"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

const useExportOrganizationPatients = (onSettled?: () => void) => {
  const { operationRequest } = useClient(IClient.AIDBOX)

  const exportFn = async (organizationId: string) => {
    const response = await operationRequest(
      "Organization",
      "GET",
      "export-patients-csv",
      organizationId,
      undefined,
      undefined,
      undefined,
      { Accept: "text/csv" },
      true, // use raw response
    )

    const blob = await (response as Response).blob()
    const url = window.URL.createObjectURL(blob)
    openLinkInNewTab({ download: `${organizationId}-patients.csv`, url })
  }

  const { mutate: exportPatients, isPending: isExporting } = useMutation({
    mutationFn: exportFn,
    onSuccess: async () => {
      displayNotificationSuccess(`Patients exported successfully`)
    },
    onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, { patientId: context }))
    },
  })

  return { exportPatients, isExporting }
}

export { useExportOrganizationPatients }
