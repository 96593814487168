import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Form, useFormikContext } from "formik"
import { DropdownChangeEvent } from "primereact/dropdown"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import {
  AutoCompletePatientField,
  Button,
  DateField,
  DropdownField,
  MultiSelectField,
  OrganizationAutocomplete,
} from "commons"
import { srCategoryCodes } from "data"

import { OrderFilter } from "../types"
import { getStatusesByType } from "../utils"

const OrdersFilters = ({ hasActiveFilters, onClearFilters }: Props) => {
  const [params] = useSearchParams()
  const { values, initialValues, isSubmitting, setFieldValue } = useFormikContext<OrderFilter>()
  const srCategories = Object.values(srCategoryCodes)
  const { type, status } = values

  const [statusCodes, setStatusCodes] = useState(() => {
    const type = params.get("type") ?? ""
    return getStatusesByType(type)
  })

  const handleChangeType = (e: DropdownChangeEvent) => {
    const type = e.value
    setStatusCodes(getStatusesByType(type))
  }

  useEffect(() => {
    const statusFiltered = status?.filter((x) => statusCodes.find((y) => y.code === x))
    setFieldValue("status", statusFiltered)
  }, [type])

  return (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        label="Type"
        field="type"
        options={srCategories}
        optionLabel="display"
        showClear
        inputClassName="text-sm"
        handleChange={handleChangeType}
      />
      <AutoCompletePatientField label="Patient" field="patient" inputClassName="text-sm w-full" />
      <OrganizationAutocomplete
        inputClassName="text-sm w-full"
        label="Organization"
        field="organization"
        showClearIcon
      />
      <MultiSelectField
        label="Status"
        field="status"
        options={statusCodes}
        optionLabel="display"
        showClear
        inputClassName="text-sm"
      />
      <DateField label="Authored on" field="authored" inputClassName="text-sm pl-2" maxDate={new Date()} />
      <DateField label="Occurrence" field="occurrence" inputClassName="text-sm pl-2" maxDate={new Date()} />
      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || !hasActiveFilters}
          icon={faFilterSlash}
          onClick={onClearFilters}
          className="p-button-sm mr-3"
          buttonStyle="default"
        />
        <Button
          type={JSON.stringify(initialValues) === JSON.stringify(values) ? "button" : "submit"}
          label="Search"
          loading={isSubmitting}
          className="p-button-sm button-primary"
        />
      </div>
    </Form>
  )
}

type Props = {
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onClearFilters(): void
}

export { OrdersFilters }
