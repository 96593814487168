const subsnotificationQueryKeys = {
  all: ["subsnotification"] as const,
  list: (filter: string, status?: string, occurrence?: Date) => [
    ...subsnotificationQueryKeys.all,
    filter,
    status,
    occurrence,
  ],
}

export { subsnotificationQueryKeys }
