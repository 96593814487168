import { ImportProgressRecord } from "./types"

const defaultProgressData: ImportProgressRecord = {
  rowCount: 0,
  endOfResponse: false,
  createdCount: 0,
  updatedCount: 0,
  failedCount: 0,
  skippedCount: 0,
}

export { defaultProgressData }
