import { Form, Formik, FormikProps } from "formik"
import { Reference } from "fhir"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { Button, DropdownField } from "commons"

import { FilterProps } from "../types"

const OrganizationFilters = ({ initialValues, roots, hasActiveFilters, onSearch, onClearFilters }: Props) => {
  const statusTypes = [
    { code: "true", display: "Active" },
    { code: "false", display: "Deactivated" },
  ]
  const renderForm = ({ isSubmitting, initialValues, values }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        field="active"
        label="Status"
        optionLabel="display"
        options={statusTypes}
        inputClassName="text-sm"
        showClear
      />
      {!!roots?.length && (
        <DropdownField
          field="orgRoot"
          label="Root Organization"
          optionLabel="display"
          options={roots}
          inputClassName="text-sm"
          showClear
          className="w-full"
          dataKey="id"
        />
      )}
      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || !hasActiveFilters}
          icon={faFilterSlash}
          onClick={onClearFilters}
          className="p-button-sm mr-3"
          buttonStyle="default"
        />
        <Button
          type={JSON.stringify(initialValues) === JSON.stringify(values) ? "button" : "submit"}
          label="Search"
          loading={isSubmitting}
          className="p-button-sm button-primary"
        />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch} enableReinitialize>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onSearch(filters: FilterProps): void
  onClearFilters(): void
  roots?: Reference[]
}

export { OrganizationFilters }
